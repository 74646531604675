/*
 * @LastEditors: chenjunsong 779217162@qq.com
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-02-08 10:55:36
 * @FilePath: \sniff-web2.0\src\config\localeConfig.ts
 * @Description: 国际化语言配置
 */
// 多语言配置，目前只有英文和简体中文

export type localeType = {
  key: string // 'en-US' | 'zh-CN' | 'zh-TW';
  label: string
}

export const LOCALE_LIST: localeType[] = [
  {
    key: 'en-US',
    label: 'English'
  },
  {
    key: 'zh-CN',
    label: '简体中文'
  },
  {
    key: 'zh-TW',
    label: '繁体中文'
  }
]
