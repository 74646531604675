/*
 * @LastEditors: chenjunsong 779217162@qq.com
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-02-09 11:35:38
 * @FilePath: \sniff-web2.0\src\store\models\user\index.ts
 * @Description:
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { UseService } from '@/services'

type IState = {
  // 用户信息
  userInfo: Record<string, any>
  // 用户头像
  userAvatar: string
  // 是否登录成功
  isLogin: boolean
  // 用户角色
  role: Types.UserRoleType
  // 权限路由
  permissionMenuData: Permisson.PermissonMenuData
}

const initialState: IState = {
  userInfo: {},
  userAvatar: '',
  isLogin: false,
  permissionMenuData: {},
  role: 'admin'
}

export const userInfoAsync = createAsyncThunk('user/fetchUseInfo', async () => {
  const response = await UseService.getUserInfo()
  // The value we return becomes the `fulfilled` action payload
  return response.data
})

export const userPermissionAsync = createAsyncThunk(
  'user/userPermission',
  async () => {
    const response = await UseService.getUserPermission()
    return response.data
  }
)

export const getUserAvatarAsync = createAsyncThunk(
  'user/getUserAvatar',
  async () => {
    const response: any = await UseService.getUserAvatar()
    // The value we return becomes the `fulfilled` action payload
    let blobUrl = ''
    let url = window.URL || window.webkitURL
    if (response.status !== 'failure') {
      blobUrl = url.createObjectURL(response.data)
    }
    return blobUrl
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    saveUserInfo(state, action) {
      return {
        ...state,
        userInfo: action.payload
      }
    },
    saveUserIsLogin(state, action) {
      return {
        ...state,
        isLogin: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userInfoAsync.fulfilled, (state, action) => {
      state.userInfo = action.payload
      console.log(state.userInfo);
    })

    builder.addCase(getUserAvatarAsync.fulfilled, (state, action) => {
      state.userAvatar = action.payload
    })

    builder.addCase(userPermissionAsync.fulfilled, (state, action) => {
      state.permissionMenuData = action.payload
    })
  }
})

export const { saveUserInfo, saveUserIsLogin } = userSlice.actions

export default userSlice.reducer
