import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ClientServices } from '@/services'
import { regionSortAndDeal } from '@/hooks/dealAreaData'
import i18next from 'i18next'

type IState = {
  locale: string
  searchKeyList: {
    [key: string]: string
  }
  companyList: Types.CompanyListItem[]
  teamsList: Types.CompanyListItem[]
  myCompanyList: Types.CompanyListItem[]
  myTeamsList: Types.CompanyListItem[]
  industryGroups?: Types.IndustryGroupListItem[]
  industries?: Types.IndustryGroupListItem[]
  projectNaturesList?: Types.IndustryGroupListItem[]
  officesList: Types.IndustryGroupListItem[]
  locationList: any[]
  projectLocationList: any[]
}

type companyListType = {
  key: string
  parentKey: string
  value: {
    'en-US': string
    'zh-CN': string
    'zh-TW': string
  }
}
type otherListType = {
  key: string
  value: {
    'en-US': string
    'zh-CN': string
    'zh-TW': string
  }
}

type getCompanyListAsyncType = {
  companies: companyListType[]
  offices: otherListType[]
}

const dealCompanyList = (response: any) => {
  const companyList: Types.CompanyListItem[] =
    response.data?.companies?.map((ele: any) => {
      return {
        label:
          ele.value?.[i18next.language as keyof Types.lngType] ||
          ele.value['en-US'],
        key: ele.key,
        value: `${ele.value?.[i18next.language as keyof Types.lngType]}|${
          ele.key
        }`,
        parentKey: ele.parentKey
      }
    }) || []

  // const teamsList: Types.TeamsListItem[] =
  //   response.data?.teams?.map((ele: any) => {
  //     return {
  //       label:
  //         ele.value?.[i18next.language as keyof Types.lngType] ||
  //         ele.value['en-US'],
  //       key: ele.key,
  //       parentKey: ele.parentKey,
  //       value: `${ele.key}`
  //     }
  //   }) || []
  const teamsList: Types.CompanyListItem[] = formatKeyValue(
    response.data,
    'teams'
  )

  const industries: Types.IndustryGroupListItem[] = formatKeyValue(
    response.data,
    'industries'
  )
  const industryGroups: Types.IndustryGroupListItem[] = formatKeyValue(
    response.data,
    'industryGroups'
  )
  const officesList: Types.IndustryGroupListItem[] = getOfficeList(
    response.data
  )

  const projectNaturesList: Types.IndustryGroupListItem[] = formatKeyValue(
    // 项目性质
    response.data,
    'projectNatures'
  )

  return {
    companyList,
    teamsList,
    industryGroups,
    industries,
    officesList,
    projectNaturesList
  }
}

function formatKeyValue(data: any, key: string) {
  if (data && data?.[key]) {
    return (
      data?.[key].map((ele: any) => {
        return {
          label:
            ele.value?.[i18next.language as keyof Types.lngType] ||
            ele.value['en-US'],
          key: ele.key,
          value: `${ele.key}`
        }
      }) || []
    )
  }
  return []
}

// 办公地址通过公司列表和办公地址列表获取，所以需要单独处理
function getOfficeList(data: getCompanyListAsyncType): any {
  if (data && data?.offices && data?.companies) {
    const companyParentKeyMap = data.companies.map((c) => c.parentKey)
    let companyParentKeySet = new Set(companyParentKeyMap)
    let officesList = data.offices.filter((o) => companyParentKeySet.has(o.key))
    if (officesList && officesList.length) {
      return (
        officesList?.map((ele) => {
          return {
            label:
              ele.value?.[i18next.language as keyof Types.lngType] ||
              ele.value['en-US'],
            key: ele.key,
            value: `${ele.key}`
          }
        }) || []
      )
    }
    return []
  }
}

// 获取公司列表
export const getCompanyListAsync = createAsyncThunk(
  'comm/fetchCompanyList',
  async () => {
    const response = await ClientServices.getCompanyList()
    return dealCompanyList(response)
  }
)

// 获取我的项目关联公司列表
export const getMyCompanyListAsync = createAsyncThunk(
  'comm/fetchMyCompanyList',
  async () => {
    const response = await ClientServices.getCompanyList({
      projectRoleType: 'MyProject'
    })
    return dealCompanyList(response)
  }
)

// 获取区域列表
export const getLocationListAsync = createAsyncThunk(
  'comm/fetchLocationList',
  async () => {
    const response = await ClientServices.getLocationData()
    const resData = response?.data || []
    const localData = regionSortAndDeal(resData)
    return localData
  }
)

// 公共部分可保存在这里
const initialState: IState = {
  locale: 'zh-CN', // 语言
  searchKeyList: {}, // 搜索关键词列表
  companyList: [], // 公司列表
  teamsList: [],
  industryGroups: [], // 行业组列表
  industries: [], // 行业列表
  myCompanyList: [], // 用户关联的公司列表
  myTeamsList: [], // 用户关联的团队列表
  locationList: [],
  projectLocationList: [], // 客户落地页后端数据中的地点列表
  projectNaturesList: [], // 项目性质
  officesList: [] // 办公地点
}

export const commSlice = createSlice({
  name: 'comm',
  initialState,

  reducers: {
    // 切换语言
    changeLocale: (state, action) => {
      state.locale = action.payload
    },
    // 搜索关键词列表
    setSearchKeyList: (state, action) => {
      console.log('action.payload', action.payload)
      state.searchKeyList = action.payload
    },
    // 设置客户落地页后端数据中的地点列表
    setProjectLocationList: (state, action) => {
      console.log('action.payload', action.payload)
      state.projectLocationList = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyListAsync.fulfilled, (state, action) => {
      state.companyList = action.payload.companyList
      state.teamsList = action.payload.teamsList
      state.industryGroups = action.payload.industryGroups
      state.industries = action.payload.industries
      state.officesList = action.payload.officesList
      state.projectNaturesList = action.payload.projectNaturesList
    })
    builder.addCase(getMyCompanyListAsync.fulfilled, (state, action) => {
      state.myCompanyList = action.payload.companyList
      state.myTeamsList = action.payload.teamsList
    })
    builder.addCase(getLocationListAsync.fulfilled, (state, action) => {
      state.locationList = action.payload
    })
  }
})

export const { changeLocale, setSearchKeyList, setProjectLocationList } =
  commSlice.actions

export default commSlice.reducer
