/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-06-05 14:57:00
 * @FilePath: \sniff-web2.0\src\config\msConfig.ts
 * @Description: 用于微软鉴权登录认证配置
 */
/// 获取用户信息，用户头像地址
export const msBaseApi = 'https://graph.microsoft.com/v1.0/me'
export const AZURE = {
  CLIENT_ID: '64e8415a-0e84-4e11-b692-1d2b68b787c9', //客户端id
  TENANT_ID: 'ab6f0e1c-73be-44c3-b7fa-2e8dde81aae7', // 租赁id
  LOGIN_API: 'https://login.microsoftonline.com' // 微软登录api地址
}
