/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-09 15:45:34
 * @LastEditTime: 2023-08-24 09:08:55
 * @FilePath: \sniff-web2.0\src\services\client\home.ts
 * @Description: Home 页面api
 */
import { http } from '@/utils/http'
import { newSniffUrl } from '@/config' // fishnetUrl 是测试使用，后期开发不要使用这个api前缀，使用sniffUrl

const HomeApi = {
  // 公共查询和个人查询记录接口
  getRecentSearch: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/search/get-recent-search`, params),
  // 公共查询和个人查询记录接口
  searchProjectName: <T>(params?: T): Promise<Types.ResponseData> =>
    http.post(`${newSniffUrl}/search/project-name`, params),
  // 获取区域信息
  getLocationData: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/metadata/areas`, params)
}

export default HomeApi
