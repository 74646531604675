/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2023-02-14 08:38:47
 * @LastEditTime: 2023-03-05 09:03:36
 * @Description:
 */
import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const Page404 = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('Not Found')}
      extra={
        <Button
          onClick={() => {
            navigate(-1)
          }}
          type="primary"
        >
          {t('GO Back')}
        </Button>
      }
    />
  )
}
export default Page404
