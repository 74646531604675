/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2024-09-18 10:16:45
 * @FilePath: \sniff-web2.0\src\store\index.ts
 * @Description: redux 入口文件
 */
import { configureStore } from '@reduxjs/toolkit' // , ThunkAction, Action
// import counterReducer from '../features/counter/counterSlice'
import menusReducer from './models/menus'
import userReducer from './models/user'
import commReducer from './models/comm'
import logReducer from './models/log'
import managementConsoleReducer from './models/management-console'

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    menus: menusReducer,
    user: userReducer,
    comm: commReducer,
    log: logReducer,
    mConsole: managementConsoleReducer
  }
})
