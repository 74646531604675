/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2024-09-12 18:04:01
 * @FilePath: \sniff-web2.0\src\services\client.ts
 * @Description:
 */
import { http } from '@/utils/http'
import { sniffUrl } from '@/config' // fishnetUrl 是测试使用，后期开发不要使用这个api前缀，使用sniffUrl
// 首页
import HomeApi from '@/services/client/home'
// 落地页
import ProjectListApi from '@/services/client/projectList'
// 个人中心
import PersonalCenter from '@/services/client/personalCenter'
// 数据质量看板
import dataDashboard from '@/services/client/dataDashboard'
// 数据质量看板详情
import dataDashboardDetail from '@/services/client/dataDashboardDetail'
// 资源设置
import resourceSetting from '@/services/client/resourceSetting'
// 用户权限设置
import userPermissions from '@/services/client/userPermissions'

export const ClientServices = {
  // 示例：fishnet 项目列表查询 【旧项目，测试用】
  // getProjectList: <T>(params?: T): Promise<Types.ResponseData> =>
  //   http.get(`${fishnetUrl}/project/list`, params),
  // 示例：sniff 项目列表查询 【用这个】
  getSniffProjectList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.post(`${sniffUrl}/search/project`, params),

  // Home 页面api
  ...HomeApi,
  // 客户落地页 api
  ...ProjectListApi,
  // 个人中心
  ...PersonalCenter,
  // 数据质量看板
  ...dataDashboard,
  // 数据质量看板详情
  ...dataDashboardDetail,
  // 权限设置
  ...userPermissions,
  // 资源设置
  ...resourceSetting

}
