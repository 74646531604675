/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2023-02-28 09:05:01
 * @LastEditTime: 2023-04-08 10:59:56
 * @Description:
 */
import { http } from '@/utils/http'
import { newSniffUrl } from '@/config' // fishnetUrl 是测试使用，后期开发不要使用这个api前缀，使用sniffUrl

const dataDashboardDetail = {
  // 团队项目明细
  getTeamProjects: <T>(code?: T, params?: any): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/report/${code}/projects`, params)
}

export default dataDashboardDetail
