import React, { useMemo } from 'react'
// import { useMenuInfo } from '@/hooks/menuInfo'
import { Button } from 'antd'
import type { ButtonProps } from 'antd/es/button'
import { useAppSelector } from '@/store/hooks'

export interface PButtonProps extends ButtonProps {
  authKey?: string | undefined
  children?: React.ReactNode
}

export type activityItem = {
  id: string
  key: string
}

export const PButton = ({ authKey, ...props }: PButtonProps) => {
  // const menuInfo = useMenuInfo()
  // const { btnAuthList } = menuInfo
  const permissionMenuData = useAppSelector(
    (state) => state.user.permissionMenuData
  )

  const btnAuthList = useMemo(() => {
    return permissionMenuData?.activity?.map((item: activityItem) => item.key)
  }, [permissionMenuData])

  const isAuthButton = useMemo(() => {
    // 不传authKey按钮默认显示git
    if (authKey === undefined) return true
    if (!Array.isArray(btnAuthList)) {
      return false
    }
    return btnAuthList.includes(authKey)
  }, [authKey, btnAuthList])

  return <>{isAuthButton ? <Button {...props}>{props.children}</Button> : ''}</>
}
