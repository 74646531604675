import { http } from '@/utils/http'
import { msBaseApi, hrsUrl } from '@/config'

export const UseService = {
  // 用户信息
  getUserInfo: () => http.get(`${msBaseApi}`),
  // 用户头像
  getUserAvatar: () =>
    http.get(`${msBaseApi}/photo/$value`, {}, { responseType: 'blob' }),
  // 用户权限
  getUserPermission: () =>
    http.get(
      `${hrsUrl}/user/permission`,
      {},
      { headers: { '-tenant-': 'aidesktop' } }
    )
}
