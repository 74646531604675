import React, { Suspense } from 'react'
import { Spin } from 'antd'
export const lazyLoad = (children: JSX.Element) => {
  return (
    <Suspense
      fallback={
        <div style={{ paddingTop: 100, textAlign: 'center', height: `calc(100vh-100px)` }}>
          <Spin size="small" />
        </div>
      }
    >
      {children}
    </Suspense>
  )
}
