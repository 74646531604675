/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-03-07 11:49:40
 * @FilePath: \sniff-web2.0\src\pages\login\index.tsx
 * @Description:
 */
import React, { useEffect } from 'react'
import { Button } from 'antd'
import { WindowsOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import { setup } from '@/config'
import { saveUserIsLogin } from '@/store/models/user'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useTranslation } from 'react-i18next'

import styles from './index.less'
// import { homePageUrl } from '@/config'
const { MsalClient, CacheKey, Scopes } = setup

const refreshToken = () => {
  window.localStorage.clear()
  MsalClient.loginRedirect({ scopes: Scopes })
  // MsalClient.loginRedirect({scopes:["openid", "profile", "User.Read"]});
}
const toLogin = (dispatch: Redux.AppDispatch) => {
  if (MsalClient.getAccount()) {
    //登录回调执行函数
    MsalClient.acquireTokenSilent({ scopes: Scopes })
      .then((res) => {
        const { expiresOn, accessToken } = res
        window.localStorage.setItem(
          CacheKey.AccessToken,
          JSON.stringify({ expiresOn: expiresOn.getTime(), token: accessToken })
        )
        // history.push(HOME);
        dispatch(saveUserIsLogin(true))
      })
      .catch((e) => {
        console.log('get token error', e)
        MsalClient.acquireTokenRedirect({ scopes: Scopes })
      })
  } else {
    refreshToken()
  }
}

export const Login = () => {
  const navigate = useNavigate()
  const { t } = useTranslation() // 用于多语言
  const dispatch = useAppDispatch()
  const userIsLogin = useAppSelector((state) => state.user.isLogin)
  // const renderRef = useRef(true)
  useEffect(() => {
    // if (renderRef.current) {
    //   renderRef.current = false
    //   return
    // }
    toLogin(dispatch)
  }, [])

  useEffect(() => {
    let redirectUrl = sessionStorage.getItem('Current_Page')
    if (!redirectUrl) redirectUrl = '/'
    userIsLogin && navigate(redirectUrl)
  }, [userIsLogin, navigate])

  const login = () => {
    toLogin(dispatch)
    // navigate('/app')l
  }

  return (
    <div className={styles.loginMain}>
      <div className={styles.loginCont}>
        <h3 className={styles.title}>
          {t('Please login using your Microsoft account')}
        </h3>
        <div className="subtitle">
          <Button icon={<WindowsOutlined />} onClick={login}>
            {t('Microsoft')}
          </Button>
        </div>
      </div>
    </div>
  )
}
