import { useTranslation } from 'react-i18next' // 用于多语言
// 国际化语言
import enUS from 'antd/es/locale/en_US'
import zhCN from 'antd/es/locale/zh_CN'
import zhTW from 'antd/es/locale/zh_TW'

// import { formatMoney } from '@/hooks/comm'

// 国际化语言
export const LANGUAGE_OPTIONS: Types.lngType = {
  'en-US': enUS,
  'zh-CN': zhCN,
  'zh-TW': zhTW
}

// 用于地区/公司下拉列表的【全部】选项
export const allObj: Types.lngType = {
  'zh-CN': '全部',
  'zh-TW': '全部',
  'en-US': 'All'
}

// 数据质量看板的项目状态
// 状态有 ： Created/Pitching/PostShowCompleted/ProjectClosed/ProjectLoss/Quotation Stage/Show Closed

/** 展会面积
 * 0-30m²
30-50m²
50-100m²
100-200m²
200-500m²
500 m²以上
 */

/* 

  {
    label: '0-30m²',
    value: 0
  },
  {
    label: '30-50m²',
    value: 1
  },
  {
    label: '50-100m²',
    value: 2
  },
  {
    label: '100-200m²',
    value: 3
  },
  {
    label: '200-500m²',
    value: 4
  },
  {
    label: '>500m²',
    value: 5
  }

*/

export const ProjectSizeOptions = [
  {
    label: '0-30m²',
    value: 0
  },
  {
    label: '30-50m²',
    value: 1
  },
  {
    label: '50-100m²',
    value: 2
  },
  {
    label: '100-200m²',
    value: 3
  },
  {
    label: '200-500m²',
    value: 4
  },
  {
    label: '>500m²',
    value: 5
  }
]

type OptionsType = {
  date: Antd.OptionsItem<number>[]
  segment: Antd.OptionsItem<string>[]
  budgetScope: Antd.OptionsItem<number>[]
}

// 首页下拉筛选值 ， date 日期 ； segment；展厅类型； budgetScope ；报价范围
// 现已更改 ，首页/数据质量看板/数据质量看板详情为下拉框，客户落地页为时间范围选择器
// budgetScope 页为下拉框/ 客户落地页更改为星级评分，
export const useAllOptions = (): OptionsType => {
  const { t } = useTranslation()

  return {
    date: [
      {
        label: t('1 months ago'),
        value: 20
      },
      {
        label: t('3 months ago'),
        value: 40
      },
      {
        label: t('6 months ago'),
        value: 60
      },
      {
        label: t('1 years ago'),
        value: 80
      },
      {
        label: t('custom time'),
        value: 100
      }
    ],
    segment: [
      {
        label: t('Conference'),
        value: 'Conference'
      },
      {
        label: t('Exhibition'),
        value: 'Exhibition'
      },
      {
        label: t('Roadshow'),
        value: 'Roadshow'
      },
      {
        label: t('Airport'),
        value: 'Airport'
      },
      {
        label: t('Metaverse'),
        value: 'Metaverse'
      },
      {
        label: t('Museum'),
        value: 'Museum'
      },
      {
        label: t('Exhibition Hall'),
        value: 'Exhibition Hall'
      }
    ],
    // budgetScope: [
    //   {
    //     label: `<${formatMoney('100000')}`,
    //     value: 25
    //   },
    //   {
    //     label: `${formatMoney('100000')}-${formatMoney('500000')}`,
    //     value: 50
    //   },
    //   {
    //     label: `${formatMoney('500000')}-${formatMoney('1000000')}`,
    //     value: 75
    //   },
    //   {
    //     label: `>${formatMoney('1000000')}`,
    //     value: 100
    //   }
    // ],
    budgetScope: [
      {
        label: `★`,
        value: 1
      },
      {
        label: `★★`,
        value: 2
      },
      {
        label: `★★★`,
        value: 3
      },
      {
        label: `★★★★`,
        value: 4
      },
      {
        label: `★★★★★`,
        value: 5
      }
    ]
  }
}

type MasksOptionsType = {
  date: {
    [key: number]: string
  }
  budgetScope: {
    [key: number]: string
  }
  dateVal: {
    [key: number]: string | number
  }
  sizeVal: {
    [key: number]: [number, number | undefined]
  }
  budgetScopeVal: {
    [key: number]: [number, number]
  }
}

// masks 选项返回的对应值，为什么这么做？
// 因为有多个地方调用，并且silder 组件，是根据数字的大小来平分节点的，下方递增是的键值是为了，让slider各节点间距保持一致
export const masksOptions: MasksOptionsType = {
  // 视图上的值
  date: {
    0: 'ALL',
    20: '1',
    40: '3',
    60: '6',
    80: '12',
    100: '36'
  },
  budgetScope: {
    0: '⭐',
    25: '⭐',
    50: '⭐',
    75: '⭐',
    100: '⭐'
  },
  // 对应的取值
  dateVal: {
    0: 0,
    20: 1,
    40: 3,
    60: 6,
    80: 12,
    100: 36
  },
  sizeVal: {
    0: [0, 30],
    1: [30, 50],
    2: [50, 100],
    3: [100, 200],
    4: [200, 500],
    5: [500, undefined]
  },
  budgetScopeVal: {
    1: [0, 5000],
    2: [5000, 10000],
    3: [10000, 50000],
    4: [50000, 100000],
    5: [100000, 1000000]
  }
}

// 【所有】的分类列表
export const ALL_CATEGORIES_LIST: Types.CategoriesItemType[] = [
  {
    key: 'All',
    text: {
      'zh-CN': '所有',
      'en-US': 'All',
      'zh-TW': '所有',
    },
  }
]

export const OTHER_CATEGORIES_LIST: Types.CategoriesItemType[] = [
  {
    key: 'Project',
    text: {
      'zh-CN': '项目',
      'en-US': 'Project',
      'zh-TW': '項目',
    },
    child: [
      {
        key: 'Contract',
        text: {
          'zh-CN': '合同',
          'en-US': 'Contract',
          'zh-TW': '合同',
        },
      },
      {
        key: 'Proposal',
        text: {
          'zh-CN': '标书',
          'en-US': 'Proposal',
          'zh-TW': '標書',
        },
      },
      {
        key: 'Item',
        text: {
          'zh-CN': '材料清单',
          'en-US': 'Item',
          'zh-TW': '材料清單',
        },
      },
      {
        key: 'Other',
        text: {
          'zh-CN': '其它',
          'en-US': 'Other',
          'zh-TW': '其它',
        },
      },

    ]
  },
  {
    key: 'Design',
    text: {
      'zh-CN': '设计',
      'en-US': 'Design',
      'zh-TW': '设计',
    },
    child: [

      {
        key: '3D',
        text: {
          'zh-CN': '3D图片',
          'en-US': '3D Photos',
          'zh-TW': '3D圖片',
        },
      },
      {
        key: '2DFile',
        text: {
          'zh-CN': '2D源文件',
          'en-US': '2D Source Files',
          'zh-TW': '2D源文件',
        },
      },
      {
        key: '2D',
        text: {
          'zh-CN': '2D图片',
          'en-US': '2D Photos',
          'zh-TW': '2D圖片',
        },
      },
      {
        key: 'CAD',
        text: {
          'zh-CN': 'CAD图片',
          'en-US': 'CAD',
          'zh-TW': 'CAD圖片',
        },
      },
      {
        key: 'Plan',
        text: {
          'zh-CN': '方案',
          'en-US': 'Plan',
          'zh-TW': '方案',
        },
      },
      {
        key: '3D Model',
        text: {
          'zh-CN': '3D模型',
          'en-US': '3D Model',
          'zh-TW': '3D模型',
        },
      },
      {
        key: 'Other',
        text: {
          'zh-CN': '其它',
          'en-US': 'Other',
          'zh-TW': '其它',
        },
      },
    ]
  },
  {
    key: 'Photo',
    text: {
      'zh-CN': '现场照片',
      'en-US': 'Photo',
      'zh-TW': '现场照片',
    },
    child: [
      {
        key: 'Photo',
        text: {
          'zh-CN': '现场照片',
          'en-US': 'Photo',
          'zh-TW': '现场照片',
        },
      },
      {
        key: 'Show',
        text: {
          'zh-CN': '展会照片',
          'en-US': 'Show',
          'zh-TW': '展會照片',
        },
      },
      {
        key: 'Project',
        text: {
          'zh-CN': '项目照片',
          'en-US': 'Project',
          'zh-TW': '項目照片',
        },
      },
      {
        key: 'Other',
        text: {
          'zh-CN': '其它照片',
          'en-US': 'Other',
          'zh-TW': '其它照片',
        },
      },
    ]
  },
  {
    key: 'Other',
    text: {
      'zh-CN': '其他文件',
      'en-US': 'Other',
      'zh-TW': '其他文件',
    },
    child: [
      {
        key: 'Other',
        text: {
          'zh-CN': '其他文件',
          'en-US': 'Other',
          'zh-TW': '其他文件',
        },
      },
      {
        key: 'Contract',
        text: {
          'zh-CN': '合同',
          'en-US': 'Contract',
          'zh-TW': '合同',
        },
      },
      {
        key: 'Project',
        text: {
          'zh-CN': '项目',
          'en-US': 'Project',
          'zh-TW': '項目',
        },
      },
    ]
  },
]