/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-06-05 14:54:32
 * @FilePath: \sniff-web2.0\src\config\commConfig.ts
 * @Description: 公共配置， 参考文档： https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-core
 */
import * as Msal from 'msal'
import { AZURE } from '@/config'
export const GET_TOKEN_KEY = window.__POWERED_BY_QIANKUN__
  ? 'helpdesk-token'
  : 'token' // 'token' // 保存到localstorage时的字段名
export const HEADER_TOKEN_KEY = 'Authorization' // 发送请求时请求头要带的token字段名
export const HEADER_USER_ID = '-user-id-' // 头部userId,区分用户email
export const SUCCESS_CODE = 200 // 成功状态码

// 回调地址，在本地调试中的回调地址，需要使用localhost:3000
let callBackUrl = `${process.env.REACT_APP_DOMAIN}${process.env.REACT_APP_ROUTER_BASE_URL}/login`
export const MENU_BY_AUTH: boolean = true
export const homePageUrl: string = `${process.env.REACT_APP_ROUTER_FIRST_PAGE}` // 配置登录后第一个进入的页面

// 启动变量
export const setup = {
  CacheKey: { AccessToken: GET_TOKEN_KEY },
  siteName: 'Pico Desktop',
  Scopes: ['openid', 'profile', 'User.Read'], // 认证过程中的参数携带
  MsalClient: new Msal.UserAgentApplication({
    auth: {
      clientId: AZURE.CLIENT_ID, // 客户端id
      authority: `${AZURE.LOGIN_API}/${AZURE.TENANT_ID}`, // 自定义授权页面路径
      redirectUri: callBackUrl // 回调地址
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  })
}
