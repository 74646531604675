/*
 * @LastEditors: junsong Chen
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2024-11-08 13:03:50
 * @FilePath: \sniff-web2.0\src\store\models\user\index.ts
 * @Description:
 */
import { isArray, isEmpty } from 'lodash'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ClientServices } from '@/services'
import { isValidJSON, isNotEmptyArr } from '@/hooks/comm'
import i18n from "@/i18n";

type IState = {
    // 当前公司的角色列表, 用于管理控制台选取
    roleList: any[],
    // 当前用户负责公司列表
    userCompanyList: any[]
}

const initialState: IState = {
    roleList: [],
    userCompanyList: []
}

type RoleItemType = {
    key: string;
    value: string
}

export type NRoleItemType = {
    label?: string;
    text: string;
    value: string;
}

// 处理角色列表响应数据
function dealRoleListData(roleList: RoleItemType[]) {
    if (isArray(roleList) && !isEmpty(roleList)) {
        const nRoleList: NRoleItemType[] = []
        roleList.forEach((ele: RoleItemType) => {
            if (ele.key && ele.value && isValidJSON(ele.value)) {
                const valObj = JSON.parse(ele.value)

                const nameStr =
                    valObj['zh-CN'] || valObj['zh-TW'] || valObj['en-US'];

                // 动态加入语言
                i18n.addResource("en-US", "translation", nameStr, valObj['en-US']);
                i18n.addResource("zh-CN", "translation", nameStr, valObj['zh-CN']);
                i18n.addResource("zh-TW", "translation", nameStr, valObj['zh-TW']);

                nRoleList.push({
                    text: nameStr,
                    value: ele.key
                })
            }
        })
        return nRoleList
    } else {
        return []
    }
}

export const userRoleAsync = createAsyncThunk(
    'user/userRole',
    async () => {
        const response = await ClientServices.getUserRoles()
        const resData = response?.data || {}
        const { roles, companies } = resData
        const nRoles = isNotEmptyArr(roles) ? roles : []
        let nCompanies = []
        if (isNotEmptyArr(companies)) {
            nCompanies = companies?.map((ele: any) => {
                return {
                    label: ele.value,
                    key: ele.key,
                    value: `${ele.value}|${ele.key}`
                }
            }) || []
        }
        const newRoleList = dealRoleListData(nRoles)
        return {
            roleList: newRoleList,
            userCompanyList: nCompanies
        }
    }
)

export const managementConsole = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveRoleList(state, action) {
            return {
                ...state,
                roleList: action.payload
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(userRoleAsync.fulfilled, (state, action) => {
            const { roleList, userCompanyList } = action.payload
            state.roleList = roleList
            state.userCompanyList = userCompanyList
        })
    }
})

export const { saveRoleList } = managementConsole.actions

export default managementConsole.reducer
