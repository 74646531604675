/*
 * @LastEditors: chenjunsong 779217162@qq.com
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-02-07 17:10:38
 * @FilePath: \sniff-web2.0\src\store\hooks.ts
 * @Description: useDispatch 和 useSelector 的二次封装
 */
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<Redux.AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<Redux.RootState> = useSelector
