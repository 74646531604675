// This must be the first line in src/index.js
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'

import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from './store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'antd/dist/antd.less'
import './index.less'
import './i18n'
import './public-path'

window.mainProps = {}

export type CommonContextType = {
  masterHistory?: any // 主应用的路由
  collapsed?: boolean // 左侧菜单收起
  /**
   * qiankun 全局数据管理通信方法
   * .onGlobalStateChange((state, prev) => {
      // state: 变更后的状态; prev 变更前的状态
      console.log(state, prev);
    });
   *
  */
  onGlobalStateChange?: Function
} & Record<string, any>

export const CommonContext = createContext<CommonContextType>({})
// declare const window: any
let root: any = null
// console.log('window.__POWERED_BY_QIANKUN__', window.__POWERED_BY_QIANKUN__)
function render(props: any) {
  const { container } = props
  root =
    root ||
    ReactDOM.createRoot(
      container
        ? container.querySelector('#appRoot')
        : document.getElementById('appRoot')
    )
  root.render(
    <Provider store={store}>
      {/* <App /> */}
      <CommonContext.Provider value={{ ...props }}>
        <Router
          basename={
            window.__POWERED_BY_QIANKUN__
              ? '/aidesktop'
              : `${process.env.REACT_APP_ROUTER_BASE_URL}`
          }
        >
          <App {...props} />
        </Router>
      </CommonContext.Provider>
    </Provider>
  )
}
// const container = document.getElementById('root') as HTMLElement
// const root = createRoot(container)

// root.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     {/* <App /> */}
//     <Router basename={`${process.env.REACT_APP_ROUTER_BASE_URL}`}>
//       <App />
//     </Router>
//   </Provider>
//   // </React.StrictMode>
// )

if (!window.__POWERED_BY_QIANKUN__) {
  render({})
}

export async function bootstrap() {
  console.log('[react18] react app bootstraped')
}

export async function mount(props: any) {
  console.log('[react18] props from main framework', props)
  // props.onGlobalStateChange((state: any, prev: any) => {
  //   // state: 变更后的状态; prev 变更前的状态
  //   console.log('qqqqqqqqqqqqqqqqqq', state, prev)
  // })
  /**
   * 组件调用主应用的history
   * import { CommonContext } from '@/index'
   *
   * const commonContext = useContext(CommonContext)
   *
   * commonContext?.masterHistory?.push('router path')
   */
  window.mainProps = props
  render(props)
}

export async function unmount(props: any) {
  root.unmount()
  root = null
}

reportWebVitals()
