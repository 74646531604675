/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2024-10-08 11:28:29
 * @FilePath: \sniff-web2.0\src\utils\http.ts
 * @Description: axios 二次封装
 */
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosInstance,
  AxiosResponse
} from 'axios'
import { t } from 'i18next'
import Qs from 'qs'
import { get } from 'lodash'
import { message as Message } from 'antd'
import { store } from '@/store/index'
import { HEADER_TOKEN_KEY, SUCCESS_CODE, HEADER_USER_ID } from '@/config'
import { getErrorObj, addErrorLog, jumpPage, getToken } from '@/hooks/comm'

/**
 * 异步请求配置
 * headers 请求头，
 * timeout 请求超时设置
 * parameterType data | params 如果是data，那么post请求是正常的json模式，如果是params那是在post请求的时候，参数加到url上
 */
export interface ajaxConfig extends AxiosRequestConfig {
  parameterType?: 'data' | 'params'
}

// 域名地址
// axios.defaults.baseURL = ''
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: ''
})
//  REQUEST 请求异常拦截
axiosInstance.interceptors.request.use(
  (config) => {
    return getToken().then((token) => {
      const { user } = store.getState()
      const userInfoMail = user?.userInfo?.mail
      userInfoMail && (config.headers[HEADER_USER_ID] = userInfoMail)
      token && (config.headers[HEADER_TOKEN_KEY] = `Bearer ${token}`)
      return config
    })
  },
  (error: AxiosError) => {
    // 错误处理
    Message.error(t('Internal server error'))
    return Promise.reject(error)
  }
)

//  RESPONSE 响应异常拦截
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    const { code, msg: message } = response.data
    if (
      response.config.responseType === 'blob' ||
      response.config.responseType === 'arraybuffer'
    ) {
      if (response.headers['content-type'].indexOf('application/json') > -1) {
        if (!response.headers.validate_code_status) {
          //'文件错误'
          if (response.headers['response-error']) {
            Message.error(response.headers['response-error'])
          } else {
            Message.error(t('Wrong Request'))
          }
        }
      } else if (response.headers['content-type'] !== 'image/gif') {
        //下载文件
        // downloadFile(response)
      }
    }
    if (typeof code === 'undefined') {
      return response
    }
    if (code !== SUCCESS_CODE) {
      Message.error(message)
    }
    return response.data
  },
  (error: AxiosError) => {
    errorStatusMsg(error)
    return Promise.reject(error) // Promise.resolve(error)
  }
)

const AJAX = (
  url: string,
  params: any,
  method: string,
  {
    headers = {},
    responseType = 'json',
    timeout = 30000,
    parameterType = 'data'
  }: ajaxConfig
) => {
  let config: AxiosRequestConfig = {
    method: method,
    timeout: timeout,
    url: url,
    responseType: responseType
  }
  let configHeaders = Object.assign({}, headers)
  if (method === 'get') {
    config['headers'] = configHeaders
    config['params'] = params
  } else {
    if (parameterType === 'params') {
      config['headers'] = configHeaders
      // config['params'] = params
      config['data'] = Qs.stringify(params)
    } else {
      config['headers'] = configHeaders
      config['data'] = params
    }
  }
  return axiosInstance(config)
}

/**
 * @description: 错误状态码转义文本
 * @param { AxiosError } error 错误对象
 * @return { }
 */
function errorStatusMsg(error: AxiosError) {
  const status = get(error, 'response.status')
  console.log('error=====>', error)
  switch (status) {
    case 302:
      error.message = t('Interface Redirection')
      break
    case 400:
      error.message = t('Wrong Request')
      break
    case 401:
      error.message = t(
        'You are not logged in, or the login has timed out, please log in first'
      )
      if (window.__POWERED_BY_QIANKUN__) {
        jumpPage('my')
      } else {
        jumpPage('/login')
      }
      break
    case 403:
      error.message = t('Lack of permission, access denied')
      break
    case 404:
      error.message =
        error.request.responseURL.indexOf('/me/photo/$value') !== -1
          ? 'No photo'
          : t('Request address error')
      break
    case 408:
      error.message = t('Request timed out')
      break
    case 409:
      error.message = t('The same data already exists in the system')
      break
    case 500:
      error.message = t('Internal server error')
      break
    case 501:
      error.message = t('Service not implemented')
      break
    case 502:
      error.message = t('Gateway error')
      break
    case 503:
      error.message = t('Service not available')
      break
    case 504:
      error.message = t(
        'The service is temporarily unavailable, please try again later'
      )
      break
    case 505:
      error.message = t('HTTP version not supported')
      break
    default:
      break
  }
  if (error.message.includes('timeout'))
    error.message = t('Network request timed out')
  if (error.message.includes('Network')) {
    error.message = window.navigator.onLine
      ? t('Server error')
      : t('Network disconnected')
  }

  // 记录错误日志数据
  handleError(error)
}

/**
 * @description 记录和显示错误
 * @param {Error} error 错误对象
 */
export function handleError(error: Types.FrontError) {
  // 添加到错误日志
  addErrorLog(getErrorObj(error))
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    console.group(`%c>>>>>> Axios Error >>>>>>`, `color:red;`)
    console.log(error)
    console.groupEnd()
  }
  setTimeout(() => {
    // 这里要针对 /me/photo/$value 接口无图片 报 Not Found 不报错处理
    error.message !== 'No photo' && Message.error(error.message)
  })
}

export const http = {
  post: (url: string, params?: any, config: ajaxConfig = {}) => {
    return AJAX(url, params, 'post', config)
  },
  get: (url: string, params?: any, config: ajaxConfig = {}) => {
    return AJAX(url, params, 'get', config)
  },
  put: (url: string, params?: any, config: ajaxConfig = {}) => {
    return AJAX(url, params, 'put', config)
  },
  delete: (url: string, params?: any, config: ajaxConfig = {}) => {
    return AJAX(url, params, 'delete', config)
  }
}
