/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2023-02-22 19:25:36
 * @LastEditTime: 2024-09-24 22:15:51
 * @Description: 个人中心界面
 */
import { http } from '@/utils/http'
import { newSniffUrl } from '@/config' // fishnetUrl 是测试使用，后期开发不要使用这个api前缀，使用sniffUrl

const PersonalCenter = {
  // 收藏列表查询
  getFavoriteList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/my/collections`, params),
  // 最近浏览列表查询
  getRecentList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/my/browses`, params)
}

export default PersonalCenter
