/**
 * params routerList 所有的路由
 * parentPath 有children的要把父级的path保存传到子级
 * authRouterList 后台的权限路由信息
 *
 * return [{}] 返回授权后的路由表
 *
 */
import { MENU_BY_AUTH } from '@/config'
import { page404 } from '@/router'
export const RouterAuth = (routerList, authRouterList, parentKey = '') => {
  let newRouterList = []
  let authMenuList = [] // 所有权限的菜单key
  if (authRouterList?.menu?.length) {
    authMenuList = authRouterList.menu.map((m) => m.key)
  }
  routerList.forEach((r) => {
    if (parentKey) {
      // key 主要是给左侧菜单的 key 使用，方便高亮对应的菜单
      r.meta.parentKey = parentKey
      r.key = parentKey + '/' + r.path
    } else {
      r.key = r.path
    }
    if (r?.children) {
      // 添加404页到最后
      if (r.children.filter((r) => r.path === '*').length <= 0) {
        r.children.push(page404)
      }
      r.children = RouterAuth(r.children, authRouterList, r.key)
    }

    r.label = r.meta.title
    // 如果开启权限控制
    if (MENU_BY_AUTH) {
      if (r.meta?.autoAuth) {
        // autoAuth=true,跳过权限，不受权限控制
        newRouterList.push(r)
      } else {
        if (r?.meta?.authKey && authMenuList.includes(r.meta.authKey)) {
          newRouterList.push(r)
        }
      }
    } else {
      newRouterList.push(r) // 全部路由不受权限控制
    }
  })
  return newRouterList
}
