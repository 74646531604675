/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-12 16:39:49
 * @LastEditTime: 2024-11-13 17:52:45
 * @FilePath: \sniff-web2.0\src\services\client\projectList.ts
 * @Description:
 */
import qs from 'qs'
import { http } from '@/utils/http'
import { newSniffUrl, gUrl } from '@/config' // fishnetUrl 是测试使用，后期开发不要使用这个api前缀，使用sniffUrl

const ProjectListApi = {
  // 项目列表查询  concierge
  getProjectList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${gUrl}/ai-desktop/project-list`, params),
  // 通过projectKey项目详情
  getProjectDetail: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${gUrl}/ai-desktop/project`, params),
  // 项目详情查询
  // getProjectDetail: <T>(params?: T): Promise<Types.ResponseData> =>
  //   http.get(`${newSniffUrl}/load/project-by-key`, params),
  // 图片label查询
  // getImageLabel: <T>(params?: T): Promise<Types.ResponseData> =>
  //   http.post(`${sniffUrl}/search/image-labels`, params),
  // 图片列表查询
  getImageList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.post(`${newSniffUrl}/search/images`, params),
  // 项目详情查询图片
  getProjectPhotoDetail: <T>(params?: T): Promise<Types.ResponseData> =>
    http.post(`${newSniffUrl}/search/photos`, params),
  // 取消收藏
  removeFavorite: <T>(params?: T): Promise<Types.ResponseData> =>
    http.put(`${gUrl}/aidesktop/remove/behavior`, params),
  // 收藏
  favorite: <T>(params?: T): Promise<Types.ResponseData> =>
    http.put(`${gUrl}/aidesktop/set/behavior`, params),

  // 财务报价
  getBudgetList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/load/budget`, params),
  // 财务标书
  getProposalList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/load/proposal`, params),
  // 财务设计
  getDesignworkList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/load/designwork`, params),
  // 财务合同
  getContractList: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/load/contract`, params),
  // 获取项目详情tabs的数量
  getProjectTabs: <T>(params?: T): Promise<Types.ResponseData> =>
    http.post(`${newSniffUrl}/load/get-project-tabs`, params)
}

export default ProjectListApi
