/*
 * @LastEditors: chenjunsong 779217162@qq.com
 * @Date: 2023-02-07 16:51:41
 * @LastEditTime: 2023-02-07 16:56:54
 * @FilePath: \sniff-web2.0\src\store\models\log\index.ts
 * @Description: 前端日志，用于记录
 */
import { createSlice } from '@reduxjs/toolkit'

type IState = {
  logs: Types.CusError[]
}

// 初始化state
const initialState: IState = {
  logs: [] // 日志
}

export const logSlice = createSlice({
  name: 'log',
  initialState,

  reducers: {
    // 添加前端日志
    addFrontLog: (state, action) => {
      state.logs = action.payload
    }
  }
})

export const { addFrontLog } = logSlice.actions

export default logSlice.reducer
