import React, { useEffect, useMemo, useContext } from 'react'
import { RouteList } from './router/route-list'
import { ConfigProvider } from 'antd'
// import { GET_TOKEN_KEY, HEADER_TOKEN_KEY } from './config'
// import { getToken } from './hooks/comm'
import 'moment/locale/zh-cn'
import { useTranslation } from 'react-i18next'
// import { setHttpConfig } from '@pico-npm/pico-comm'

import { LANGUAGE_OPTIONS } from '@/config/constants'
import { CommonContext } from '.'
// setHttpConfig({
//   getToken: async () => {
//     let token = await getToken()
//     return `Bearer ${token}`
//   }
// })

type AppProps = {
  onGlobalStateChange?: any
}

const App = (props: AppProps) => {
  const { i18n } = useTranslation()
  const currentLocale: string = useMemo(() => {
    return i18n?.language
  }, [i18n.language])
  const commonContext = useContext(CommonContext)
  useEffect(() => {
    if (commonContext.onGlobalStateChange) {
      commonContext.onGlobalStateChange((state: any, prev: any) => {
        // state: 变更后的状态; prev 变更前的状态
        // console.log('rrrrrrrrrrrrrrrrrp', state, prev)
        i18n.changeLanguage(state.currentLang)
      })
    }
  }, [commonContext.onGlobalStateChange])

  return (
    <ConfigProvider
      locale={LANGUAGE_OPTIONS[currentLocale as keyof Types.lngType]}
    >
      <RouteList />
    </ConfigProvider>
  )
}

export default App
