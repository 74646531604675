/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-06-05 14:59:01
 * @FilePath: \sniff-web2.0\src\config\index.ts
 * @Description: 配置入口文件，抛出所有配置
 */
import logoPng from '@/assets/img/pico-logo.png'
export * from './apiConfig'
export * from './msConfig'
export * from './commConfig'
export * from './localeConfig'
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN
export const logo = logoPng
export const BLOB = {
  CDN_DOMAIN: 'https://concierge-blob.pico.com', // CDN域名
  CONTAINER_NAME: 'sniff', // 容器名
  DOMAIN: 'https://picoconciergeassets.blob.core.windows.net',
  SAS: `https://picoconciergeassets.blob.core.windows.net/??sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2026-03-14T15:08:23Z&st=2022-11-09T07:08:23Z&spr=https&sig=%2B7cvOCtcRZo5oxX5oqL8RbAoC3HXZSo%2FiUpEnYJLKrY%3D`
}
