/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2023-02-28 09:05:01
 * @LastEditTime: 2023-03-05 09:49:57
 * @Description:
 */
import { http } from '@/utils/http'
import { newSniffUrl, hUrl } from '@/config' // fishnetUrl 是测试使用，后期开发不要使用这个api前缀，使用sniffUrl

const dataDashboard = {
  // // 获取公司列表
  // getCompanyList: <T>(params?: T): Promise<Types.ResponseData> =>
  //   http.get(`${newSniffUrl}/metadata/companies`, params),

  // 获取公司列表
  // getCompanyList: <T>(params?: T): Promise<Types.ResponseData> =>
  //   http.get(`${hrUrl}/sniff/companys`, params),

  // 获取公司列表
  getCompanyList: <T>(params?: T): Promise<Types.ResponseData> =>
    // http.get(`${newSniffUrl}/report/metadata`, params),
    http.get(`${hUrl}/global/metadata/ai-desktop`, params),

  // 获取团队项目统计
  getTeamsReport: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/report/teams`, params),

  // 获取最近一年的项目文件统计
  getLastYearProject: <T>(params?: T): Promise<Types.ResponseData> =>
    http.get(`${newSniffUrl}/report/last-year-project`, params)
}

export default dataDashboard
