/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2024-09-25 08:51:24
 * @FilePath: \sniff-web2.0\src\config\apiConfig.ts
 * @Description: api Url 配置
 */
export const baseUrl = process.env.REACT_APP_BASE_URL
export const version = process.env.REACT_APP_API_VERSION
export const fishnetUrl = baseUrl + `/c/${version}/fishnet` // fishnet请求前缀,这里只是展示demo使用， 在后期开始开发后，会删除
export const sniffUrl = baseUrl + `/s/${version}/service` // AI Desktop(sniff)请求前缀 【以前的java服务】
export const newSniffUrl = baseUrl + `/s/${version}/api` // AI Desktop(sniff)请求前缀 【以前的java服务】

export const hrUrl = baseUrl + `/h/${version}/unified` // 调用hr服务数据
export const hrsUrl = baseUrl + `/h/${version}/hrs` // 调用hr服务数据
export const hhrUrl = baseUrl + `/h/${version}/hr` // 调用hr服务数据
export const hUrl = baseUrl + `/h/${version}`
export const gUrl = baseUrl + `/g/${version}` // 资源设置
