import React from 'react'
import { Login } from '../pages/login'
import Page404 from '../page404'
import { Client } from './client'

// 所有路由配置
/**
 * element 用来替换以前的 component
 *
 */
import * as Icon from '@ant-design/icons'
export type metaItems = {
  /* 菜单名称，主要用于国际化的key来使用，请使用英文，不要中文 */
  title: string
  /* 菜单权限，对应后台的权限码 */
  authKey?: string
  /* 按钮权限，对应后台的权限码，一个页面可能有多个按钮，使用数组来保存 */
  btnAuth?: string[] | undefined
  /* 菜单左侧图标 */
  icon?: keyof typeof Icon
  /* 是否不受后台权限数据控制，true不受控制，false会被权限控制 */
  autoAuth?: boolean
  isHiddenMenu?: boolean
} & Record<string, any>

export interface routerItem {
  path: string
  name?: string
  element?: JSX.Element
  meta?: metaItems
  children?: routerItem[]
}

// 公共路由
export const DefaultRouters: routerItem[] = [
  {
    path: '/',
    name: 'index',
    // element: <Home />, //
    meta: {
      title: 'home'
    }
    // children: [] 不需要
  },
  {
    path: '/login',
    name: 'login',
    element: <Login />,
    meta: {
      title: 'login'
    }
  }
]

export const page404 = {
  //
  path: '*',
  element: <Page404 />,
  meta: {
    title: 'page404',
    autoAuth: true // 不需要权限控制
  }
}

// 需要授权的菜单
const Routers: routerItem[] = [...Client] //

export default Routers
