/*
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-06 11:22:45
 * @LastEditTime: 2023-02-24 09:59:07
 * @FilePath: \sniff-web2.0\src\component\user\index.tsx
 * @Description: 用户
 */
import React, { useMemo } from 'react'
import { useAppSelector } from '@/store/hooks'
import { Spin, Avatar, Space, Dropdown, Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './index.less'
// import { hasPremission } from '@/hooks/permission'

export interface userDropdownItem {
  key: string
  disabled?: boolean
  authKey?: string
  label: any
}

export const User = () => {
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const userAvatar = useAppSelector((state) => state.user.userAvatar)
  // const [userDropdown, setUserDropdown] = useState<userDropdownItem[]>([])
  const { t } = useTranslation()
  const navigtor = useNavigate()

  // 权限数据
  const permissionMenuData = useAppSelector(
    (state) => state.user.permissionMenuData
  )
  // 按钮权限数据的key集合
  const menuAuthList = useMemo(() => {
    return permissionMenuData?.menu?.map((item: any) => item.key)
  }, [permissionMenuData])

  // 登出
  const logout = () => {
    window.localStorage.clear()
    window.location.href =
      'https://login.microsoftonline.com/ab6f0e1c-73be-44c3-b7fa-2e8dde81aae7/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fwww.pico.com&client-request-id=cd2a93df-6dca-471b-b9c8-8065341013c1'
  }

  // 个人中心
  const personalCenter = () => {
    navigtor('/client/personal-center')
  }

  // 数据看板
  const dataDashboard = () => {
    navigtor('/client/data-dashboard')
  }

  const items: userDropdownItem[] = [
    {
      key: '1',
      // disabled: !hasPremission('personal'),
      label: <a onClick={personalCenter}>{t('personalCenter')}</a>
    },
    {
      key: '2',
      authKey: 'menu.dataDashboard', // 这里没有使用按钮的key，是分配了数据看板页面的用户就有这个按钮
      // disabled: !hasPremission('menu.dataDashboard'),
      label: <a onClick={dataDashboard}>{t('dataDashboard')}</a>
    },
    {
      key: '3',
      label: <a onClick={logout}>{t('logout')}</a>
    }
  ]

  // const userDropdown: JSX.Element = (
  //   <Menu>
  //     {items.map((i: userDropdownItem) => {
  //       return <Menu.Item key={i.key}>{i.label}</Menu.Item>
  //     })}
  //   </Menu>
  // )

  const userDropdown: JSX.Element = useMemo(() => {
    const authList = items.filter((i) => {
      // 配置了authKey的数据要进行鉴权，否则就默认可以给用户操作
      if (i?.authKey) {
        return menuAuthList.includes(i.authKey)
      } else {
        return true
      }
    })
    return (
      <Menu>
        {authList.map((i: userDropdownItem) => {
          return <Menu.Item key={i.key}>{i.label}</Menu.Item>
        })}
      </Menu>
    )
  }, [menuAuthList, t])

  return (
    <Dropdown overlay={userDropdown} placement="bottomLeft">
      <Space className={styles['user-wrap']}>
        {userAvatar ? (
          <Avatar size="small" src={userAvatar} />
        ) : (
          <Avatar size="small" icon={<UserOutlined />} />
        )}

        {userInfo?.displayName ? userInfo.displayName : <Spin size="small" />}
      </Space>
    </Dropdown>
  )
}
