import React from 'react'
import styles from './index.less'
// 用于内容页面
type childrenPageProps = {
  children?: React.ReactElement
  style?: any
} & Record<string, any>

const childrenPage = (props: childrenPageProps): JSX.Element => {
  return (
    <div className={styles['children-page']} {...props}>
      {props.children}
    </div>
  )
}
export default childrenPage
