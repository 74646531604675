import React, { useMemo, useState, createContext } from 'react'
import styles from './index.less'
import ProLayout from '@ant-design/pro-layout'
import { MenuOutlined } from '@ant-design/icons'
import type { BasicLayoutProps } from '@ant-design/pro-layout'
import { Settings } from '@ant-design/pro-components'
import { Outlet, useLocation } from 'react-router-dom'
import ChildrenPage from '@/component/children-page'
import { useAppSelector } from '@/store/hooks'
import { findMenuList } from '@/utils/findMenuList'
import { useNavigate } from 'react-router'
import { PBaseLayout } from '@pico-npm/pico-comm'

import * as Icon from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
/***
 *
 * 此组件已经被pico-comm包里的公共组件替换，，不再使用
 *
 */

export type PBaseLayoutSetting = Omit<Settings, 'navTheme'> & {
  navTheme?: 'dark' | 'realDark' | 'light' | undefined
  primaryColor?: string
  siderWidth?: number
  autoHideHeader?: boolean
  pwa?: boolean
}

/**
 * 继承自pro-layout的BasicLayoutProps
 */
export interface PBaseLayoutProps extends BasicLayoutProps {
  /* 左上角logo图片 */
  // logoImg: string
  setting?: PBaseLayoutSetting
  /* 菜单收缩的方法 */
  toggleCollapsed?: () => void | false
  /* logo图片不再通过logo属性传，换成logoImg={xxx} */
  logo?: any
}

const setting: PBaseLayoutSetting = {
  navTheme: 'light',
  primaryColor: '#00c7b1',
  layout: 'top',
  contentWidth: 'Fluid',
  siderWidth: 272,
  fixedHeader: false,
  autoHideHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: '',
  menu: {
    locale: true
  },
  // title: 'HELPDESK',
  pwa: false,
  iconfontUrl: ''
}

export interface menuItem {
  icon: JSX.Element
  meta?: {
    icon?: keyof typeof Icon
  }
  label: string
  children?: menuItem[]
}

export const BaseLayoutContext = createContext({})

export const PMenuLayout = (props: PBaseLayoutProps) => {
  const { i18n, t } = useTranslation()
  const authRouterList = useAppSelector((state) => state.menus.authRouterList)
  // console.log('authRouterList', authRouterList)
  /**
   * setting可以通过props传进来，不传就用上面的默认值
   */
  const [layoutSetting] = useState<PBaseLayoutSetting>(() => {
    return props.setting || setting
  })
  /**
   * collapsed 传进来，
   * toggleCollapsed collapsed放大缩小的方法也是传过来
   * logoImg不再通过logo属性传，换成logoImg={xxx}
   *
   */
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const loaction = useLocation()
  const [currentMenuKey, setCurrentMenuKey] = useState<string>('')
  // const [defaultOpenKey, setDefaultOpenKeys] = useState('')
  // const routerInfo = useMenuInfo()

  const navigate = useNavigate()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  // // 当前菜单
  const currentMenu = useMemo(() => {
    const currentMenuList = findMenuList(authRouterList, loaction.pathname)
    setCurrentMenuKey(loaction.pathname)
    return currentMenuList
  }, [authRouterList, loaction])

  const currentLocale = useMemo(() => {
    return i18n.language
  }, [i18n.language])

  const setIconAndLabel = (list: menuItem[]): menuItem[] => {
    list.forEach((l) => {
      if (l?.children?.length) {
        setIconAndLabel(l.children)
      }
      const c_title = l.label
      if (l?.meta?.icon) {
        l.icon = React.createElement(Icon && (Icon as any)[l.meta['icon']])
      }
      l.label = t(c_title)
    })
    return list
  }

  // 给菜单加上图标
  const currentMenuWithIcon = useMemo(() => {
    // console.log('currentMenuWithIcon', locale)
    let m: any = []
    if (currentMenu) {
      m = setIconAndLabel(JSON.parse(JSON.stringify(currentMenu)))
    }
    return m
  }, [currentMenu, currentLocale])

  // 点击菜单转跳对应页面
  const navigatorTo = (e: any) => {
    navigate(e.key)
  }
  const isPoweredByQiankun = window.__POWERED_BY_QIANKUN__

  return (
    <PBaseLayout
      className={
        isPoweredByQiankun ? props?.logo ? 'qiankun-layout' : 'qiankun-layout no-logo' : styles['p-base-layout']
      }
      logo={props?.logo}
      toggleCollapsed={toggleCollapsed}
      collapsed={collapsed}
      logoImg={isPoweredByQiankun ? '' : props?.logo}
      menuProps={{
        items: currentMenuWithIcon,
        onClick: navigatorTo
        // mode: 'inline'
      }}
      menuRender={false}
      // {...{ route: { path: '/', routes: currentMenuWithIcon } }}
      route={{ path: '/', routes: currentMenuWithIcon }}
      location={{
        pathname: currentMenuKey
      }}
      {...{ setting: layoutSetting }}
    >
      <BaseLayoutContext.Provider value={{ setCollapsed }}>
        <ChildrenPage>
          <Outlet></Outlet>
        </ChildrenPage>
      </BaseLayoutContext.Provider>
    </PBaseLayout>
  )
}
