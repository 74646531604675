type RegionDataItemType = {
  country: string | null
  city: string | null
}

/**
 * @description: 过滤脏数据
 * @param {RegionDataItemType} arr
 * @return {*}
 */
export const filterRegionData = (arr: RegionDataItemType[]) => {
  let region: any = {}
  const regStr = /null|Not Specified|\/.|\/-|3 cites|\/null/g
  // 将有国家的城市数据分类整合
  arr.forEach((ele: RegionDataItemType, index, oriArr) => {
    // 数据必须有国家，有城市的数据
    if (ele.country && !regStr.test(ele.country)) {
      region[ele.country] = oriArr.filter((item: RegionDataItemType) => {
        if (item.country === ele.country) {
          return !regStr.test(item.city || '')
          // return true
        } else {
          return false
        }
      })
    }
  })
  console.log('region=====>', region)
  return region
}

// 城市排序并整合
export const regionSortAndDeal = (indexBarList: any[]) => {
  const filterArr = filterRegionData(indexBarList)

  const keyArr = Object.keys(filterArr)
    .map((ele) => {
      const childArr = filterArr[ele]?.map((child: RegionDataItemType) => {
        return {
          label: child.city,
          value: child.city
        }
      })
      return {
        label: ele,
        value: ele,
        children: childArr
      }
    })
    .sort(function (a, b) {
      const chatStrA = a.value?.charCodeAt(0)
      const chatStrB = b.value?.charCodeAt(0)
      return chatStrA > chatStrB ? 1 : -1
    })
  return keyArr
}
