import React, { useEffect, useMemo, useState } from 'react'
import { useRoutes, useLocation, matchRoutes, Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { saveMenusInfo, saveAuthRouter } from '@/store/models/menus'
import Routers, { DefaultRouters, page404 } from '../index'
import { RouterAuth } from '@/utils/RouterAuth'
import { RouterInfoPlan, getOnlyNeedInfo } from '@/utils/RouterInfoPlan'
import {
  userInfoAsync,
  getUserAvatarAsync,
  userPermissionAsync
} from '@/store/models/user'
import { Spin } from 'antd'
import { GET_TOKEN_KEY } from '@/config'
// import { useAppSelector } from '@/store/hooks';
// import { homePageUrl } from '@/config'
import styles from './index.less'

// 动态生成路由
export const RouteList = () => {
  const useToken: string | null = localStorage.getItem(GET_TOKEN_KEY)
  // const [prePath, setPrePath] = useState('')
  let dispatch = useAppDispatch()
  let location = useLocation()
  // const navigate = useNavigate()
  // const userInfo = useAppSelector((state) => state.user.userInfo)
  const [matchsRouter, setMatchsRouter]: any = useState([])
  const [firstPagePath, setFirstPagePath] = useState<string>('')
  const permissionMenuData = useAppSelector(
    (state) => state.user.permissionMenuData
  )
  // console.log('routerlist')
  useEffect(() => {
    if (useToken) {
      dispatch(userInfoAsync())
      dispatch(getUserAvatarAsync())
      dispatch(userPermissionAsync())
    }
  }, [useToken])

  // 动态添加路由, 如果需要进行菜单和按钮权限控制，请在这里对Routers进行操作
  const resultRouter = useMemo(() => {
    let rRouter: any = []
    if (!useToken) {
      rRouter = DefaultRouters
    } else {
      if (permissionMenuData?.menu) {
        let authRouters = RouterAuth(Routers, permissionMenuData)
        rRouter = [...authRouters, ...DefaultRouters]
        console.log('rRouter=====>', rRouter)
        setFirstPagePath(rRouter[0].path)
      }
    }
    console.log('permissionMenuData', permissionMenuData)
    // 404在所有路由的最后一个
    rRouter.push(page404)
    return rRouter
  }, [permissionMenuData])

  // 查看当前路由下面是否有子路由,有的话就默认显示子路由的第一个
  const navigateRouter = useMemo(() => {
    // 如果当前路由地址还有对应的子路由,那么就转跳到其子路由的第一个路由去
    if (matchsRouter && matchsRouter.length) {
      const currentRouter = matchsRouter.filter(
        (item: any) => item.pathname === location.pathname
      )
      if (
        currentRouter[0]?.route?.children &&
        currentRouter[0]?.route?.children.length > 0
      ) {
        return currentRouter[0].route?.children[0].key // key这个是在上一层已经写好的完整的路由地址
      } else {
        return null
      }
    }
    // 如果没有子路由,那么就不再转跳了
    return null
  }, [matchsRouter, location.pathname])

  useEffect(() => {
    // element会造成循环引用的问题，在这里只需要路由的其它配置信息就可以，剔除element
    const routerInfoWithoutElement = getOnlyNeedInfo(resultRouter)
    // 路由的所有配置保存起来，后续可以通过 useMenuInfo()来获取对应的配置
    dispatch(
      saveMenusInfo(JSON.stringify(RouterInfoPlan(routerInfoWithoutElement)))
    )
    // 保存所有权限的路由到 redux，左侧菜单数据可以对应的redux中的menu获取
    dispatch(saveAuthRouter(JSON.stringify(routerInfoWithoutElement)))
  }, [resultRouter, permissionMenuData?.menu])

  // 当前路由对应的路由树信息
  useEffect(() => {
    if (useToken) {
      const mathchs = matchRoutes(resultRouter, location)
      setMatchsRouter(mathchs)
    }
  }, [resultRouter, location.pathname, useToken])

  const routes = useRoutes(resultRouter)

  if (!useToken && location.pathname !== '/login') {
    sessionStorage.setItem('Current_Page', location.pathname)
    return <Navigate to="/login" state={{ from: location.pathname }} replace />
  }

  if (useToken && location.pathname === '/' && firstPagePath !== '') {
    return (
      <Navigate
        to={firstPagePath}
        state={{ from: location.pathname }}
        replace
      />
    )
  }

  // 登录后转跳到路由第一个子路由
  if (useToken && location.pathname === '/login' && firstPagePath !== '') {
    return (
      <Navigate
        to={firstPagePath}
        state={{ from: location.pathname }}
        replace
      />
    )
  }
  
  // 有子路由,那就转跳过去
  if (navigateRouter) {
    return (
      <Navigate
        to={navigateRouter}
        state={{ from: location.pathname }}
        replace
      />
    )
  }

  // 路由懒加载需要添加Suspense
  return (
    <>
      {!useToken ? (
        routes
      ) : permissionMenuData?.menu ? (
        routes
      ) : (
        <div className={styles['loading-wrap']}>
          <Spin tip="Loading" />
        </div>
      )}
    </>
  )
}
