/*
 * @LastEditors: junsong Chen
 * @Date: 2023-02-07 11:53:01
 * @LastEditTime: 2024-10-17 12:42:26
 * @FilePath: \sniff-web2.0\src\hooks\comm.ts
 * @Description: 项目中公共函数
 */
import moment from 'moment'
import { t } from 'i18next'
import { message as Message } from 'antd'
import { isEmpty, isObject, isArray, uniq, cloneDeep } from 'lodash'
import { setup } from '@/config'
import { store } from '@/store/index'
import { ALL_CATEGORIES_LIST, OTHER_CATEGORIES_LIST } from '@/config/constants'
const { MsalClient, CacheKey, Scopes } = setup

const DEFAULT_SESSION_PAGE_KEY = 'projectListPageData'
export const DETAIL_SESSION_PAGE_KEY = 'dataDetailPageData'
export const STANTFORMAT = 'YYYY-MM-DD 00:00:00'
const ENDFORMAT = 'YYYY-MM-DD 23:59:59'

/**
 * @description: 提示二次封装，方便用于收录不同类型的操作信息
 * @param {Antd.MessageConfig} msgConfig
 * @return {*}
 */
export const showMsg = (msgConfig: Antd.MessageConfig | string) => {
  if (!isEmpty(msgConfig)) {
    if (isObject(msgConfig)) {
      const {
        type = 'info',
        content,
        duration = 5,
        isRecord = false
      } = msgConfig
      Message.open({
        type,
        content,
        duration
      }).then((res) => {
        // 此处收录错误/警告（warning , error）的消息
        if (res && isRecord && ['warning', 'error'].includes(type)) {
          addErrorLog(
            getErrorObj({
              type,
              content,
              name: '页面操作消息',
              source: 'message'
            })
          )
        }
      })
    } else {
      Message.info(msgConfig)
    }
  } else {
    Message.warning('showMsg函数路由重定向msgConfig参数为必传项')
  }
}

/**
 * @description: 获取当前时间
 * @return {*}
 */
export const getCurrentTime = () => moment().format('YYYY-MM-DD HH:mm:ss')

/**
 * @description: 供开发期间使用的业务逻辑警告处理
 * @param {string} alertMsg
 * @return {*}
 */
const consolePicoBug = (alertMsg: string) => {
  if (process.env.NODE_ENV === 'development') {
    Message.warning(alertMsg)
  }
}

/**
 * @description: 根据类型，获取时间段
 * @param {string} type
 * @return {*}
 */
export const getRangeTime = (type: string): Types.GetRangeTime => {
  let dateFrom = ''
  let dateTo = ''
  switch (type) {
    case '今日':
      dateFrom = moment().format(STANTFORMAT)
      dateTo = moment().format(ENDFORMAT)
      break
    case '明日':
      dateFrom = moment().subtract(-1, 'days').format(STANTFORMAT)
      dateTo = moment().subtract(-1, 'days').format(ENDFORMAT)
      break
    case '前日':
      dateFrom = moment().subtract(2, 'days').format(STANTFORMAT)
      dateTo = moment().subtract(2, 'days').format(ENDFORMAT)
      break
    case '昨日':
      dateFrom = moment().subtract(1, 'days').format(STANTFORMAT)
      dateTo = moment().subtract(1, 'days').format(ENDFORMAT)
      break
    case '上周':
      dateFrom = moment().day(-6).format(STANTFORMAT)
      dateTo = moment().day(0).format(ENDFORMAT)
      break
    case '本周':
      dateFrom = moment().day(1).format(STANTFORMAT)
      dateTo = moment().day(7).format(ENDFORMAT)
      break
    case '下周':
      dateFrom = moment()
        .startOf('week')
        .subtract(-1, 'week')
        .format(STANTFORMAT)
      dateTo = moment().endOf('week').subtract(-1, 'week').format(ENDFORMAT)
      break
    case '上月':
      dateFrom = moment(new Date())
        .subtract(1, 'months')
        .startOf('month')
        .format(STANTFORMAT)
      dateTo = moment(new Date())
        .subtract(1, 'months')
        .endOf('month')
        .format(ENDFORMAT)
      break
    case '本月':
      dateFrom = moment().startOf('month').format(STANTFORMAT)
      dateTo = moment().endOf('month').format(ENDFORMAT)
      break
    case '下月':
      dateFrom = moment()
        .month(moment().month() + 1)
        .startOf('month')
        .format(STANTFORMAT)
      dateTo = moment()
        .month(moment().month() + 1)
        .endOf('month')
        .format(ENDFORMAT)
      break
    case '上季度':
      dateFrom = moment()
        .quarter(moment().quarter() - 1)
        .startOf('quarter')
        .format(STANTFORMAT)
      dateTo = moment()
        .quarter(moment().quarter() - 1)
        .endOf('quarter')
        .format(ENDFORMAT)
      break
    case '本季度':
      dateFrom = moment().startOf('quarter').format(STANTFORMAT)
      dateTo = moment().endOf('quarter').format(ENDFORMAT)
      break
    case '下季度':
      dateFrom = moment()
        .quarter(moment().quarter() + 1)
        .startOf('quarter')
        .format(STANTFORMAT)
      dateTo = moment()
        .quarter(moment().quarter() + 1)
        .endOf('quarter')
        .format(ENDFORMAT)
      break
    case '去年':
      dateFrom = moment(new Date())
        .subtract(1, 'year')
        .startOf('year')
        .format(STANTFORMAT)
      dateTo = moment(new Date())
        .subtract(1, 'year')
        .endOf('year')
        .format(ENDFORMAT)
      break
    case '今年':
      dateFrom = moment().startOf('year').format(STANTFORMAT)
      dateTo = moment().endOf('year').format(ENDFORMAT)
      break
    case '明年':
      dateFrom = moment()
        .year(moment().year() + 1)
        .startOf('year')
        .format(STANTFORMAT)
      dateTo = moment()
        .year(moment().year() + 1)
        .endOf('year')
        .format(ENDFORMAT)
      break
    case '一个月':
      dateFrom = moment().format(STANTFORMAT)
      dateTo = moment().subtract(30, 'days').format(ENDFORMAT)
      break
    case '三个月':
      dateFrom = moment().format(STANTFORMAT)
      dateTo = moment()
        .subtract(30 * 3 + 2, 'days')
        .format(ENDFORMAT)
      break
    case '六个月':
      dateFrom = moment().format(STANTFORMAT)
      dateTo = moment()
        .subtract(30 * 6 + 3, 'days')
        .format(ENDFORMAT)
      break
    case '一年':
      dateFrom = moment().format(STANTFORMAT)
      dateTo = moment().subtract(365, 'days').format(ENDFORMAT)
      break
    case '三年':
      dateFrom = moment().format(STANTFORMAT)
      dateTo = moment()
        .subtract(365 * 3, 'days')
        .format(ENDFORMAT)
      break
    case '六年':
      dateFrom = moment().format(STANTFORMAT)
      dateTo = moment()
        .subtract(365 * 6, 'days')
        .format(ENDFORMAT)
      break
  }
  return { dateFrom, dateTo }
}

/**
 * @description: 获取时间范围
 * @param {array} numArr
 * @return {*}
 */
export const getSubRangeTime = (
  numArr: [number | string, number | string],
  format: string = 'YYYY-MM-DD'
): [string, string] => {
  console.log('numArr=====>', numArr)
  const getTime = (num: number | string) =>
    moment()
      .subtract(30 * Number(num), 'days')
      .format(format)

  let dateTo = getTime(numArr[0])
  let dateFrom = getTime(numArr[1])
  console.log(dateFrom, dateTo)
  return [dateFrom, dateTo]
}

export const getSubMonthTime = () => {
  let dateTo = moment()
    .subtract(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD')
  let dateFrom = moment()
    .subtract(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD')
  return [dateFrom, dateTo]
}

/**
 * @description: 去除数组中的无效元素，
 * @param {any} arr
 * @return {*}
 */
export const filterEmptyEleArr = (arr: any[]) => {
  if (isArray(arr) && !isEmpty(arr)) {
    return arr.filter((ele) => !['', undefined, null].includes(ele))
  } else {
    return []
  }
}

/**
 * @description: 数组去重
 * @param {any} arr
 * @return {*}
 */
export const uniqueArray = (arr: any[]) => {
  if (isArray(arr) && !isEmpty(arr)) {
    return uniq(arr)
  } else {
    return []
  }
}

/**
 * @description: 获取时间段数组
 * @param {string} type
 * @return {*}
 */
export const getArrayRangeTime = (type: string) => {
  return Object.values(getRangeTime(type))
}

/**
 * @description: 获取日志对象
 * @param {Types.FrontError} error
 * @return {*}
 */
export const getErrorObj = (error: Types.FrontError): Types.CusError => {
  const { href } = window.location
  let errorObj = {
    name: '',
    content: '未知错误',
    type: 'warning',
    pageUrl: href, // 页面来源
    date: getCurrentTime(), // 发生日期
    userId: 123123, // 用户id
    username: '测试用户', // 用户名
    source: 'page', // 错误操作来源
    message: '',
    extraData: null
  }
  if (error?.name === 'AxiosError') {
    const message = error.message || 'axios未知错误'
    errorObj.name = error.name || ''
    errorObj.source = 'axios'
    errorObj.content = message
    errorObj.message = message
  } else {
    errorObj = Object.assign(errorObj, error)
  }
  return errorObj
}

/**
 * @description: 添加到错误日志中去
 * @param {Types} error
 * @return {*}
 */
export const addErrorLog = (error: Types.CusError) => {
  // 添加到redux 日志中去
  console.log('添加到redux 日志中去=====>', error)
}

/**
 * @description: 路由跳转
 * @param {string} path
 * @return {*}
 */
export const jumpPage = (path: string) => {
  if (path) {
    const { origin } = window.location
    setTimeout(() => {
      window.location.href = `${origin}/${path}`
    })
  } else {
    consolePicoBug('jumpPage函数路由重定向path参数为必传项')
  }
}

/**
 * @description: 获取token
 * @return {*}
 */
export const getToken = () => {
  return new Promise((resolve, reject) => {
    // 提前10分钟刷新Token
    const nowTime = new Date().getTime() + 10 * 60 * 1000
    const tokenJson = window.localStorage.getItem(CacheKey.AccessToken) || ''
    if (tokenJson) {
      if (!window.__POWERED_BY_QIANKUN__) {
        try {
          const item = JSON.parse(tokenJson)
          if (item?.expiresOn >= nowTime) {
            return resolve(item.token) // accessToken
          }
        } catch {
          reject(false)
        }
        MsalClient.acquireTokenSilent({ scopes: Scopes })
          .then((res) => {
            const { expiresOn, accessToken } = res
            window.localStorage.setItem(
              CacheKey.AccessToken,
              JSON.stringify({
                expiresOn: expiresOn.getTime(),
                token: accessToken
              })
            )
            resolve(accessToken)
          })
          .catch((res) => {
            window.localStorage.clear()
            window.sessionStorage.clear()
            // window.location.href = process.env.REACT_APP_PUBLIV_PATH || '/'
          })
      } else {
        const item = JSON.parse(tokenJson)
        return resolve(item.token) // accessToken
      }
      // if (!window.__POWERED_BY_QIANKUN__) {
      //   // 尝试刷新Token，不行到登录登录页面重新走一遍流程
      //   MsalClient.acquireTokenSilent({ scopes: Scopes })
      //     .then((res) => {
      //       const { expiresOn, accessToken } = res
      //       window.localStorage.setItem(
      //         CacheKey.AccessToken,
      //         JSON.stringify({
      //           expiresOn: expiresOn.getTime(),
      //           token: accessToken
      //         })
      //       )
      //       resolve(accessToken)
      //     })
      //     .catch((res) => {
      //       window.localStorage.clear()
      //       // window.location.href = process.env.REACT_APP_PUBLIV_PATH || '/'
      //     })
      // }
    } else {
      reject(false)
    }
  })
}

/**
 * @description: base64 转译 Blob 对象
 * @param {string} base64String
 * @return {*}
 */
export const base64ToBlob = (base64String: string) => {
  let str = window.atob(base64String)
  let size = str.length
  let data = new Uint8Array(size)
  while (size--) {
    data[size] = str.charCodeAt(size)
  }
  return new Blob([data], {
    type: 'application/octet-stream'
  })
}

/**
 * @description: uuid 生成
 * @return {*}
 */
export const uuid = () => {
  var temp_url = URL.createObjectURL(new Blob())
  var uuid = temp_url.toString() // blob:https://xxx.com/b250d159-e1b6-4a87-9002-885d90033be3
  URL.revokeObjectURL(temp_url)
  return uuid.substr(uuid.lastIndexOf('/') + 1)
}

/**
 * @description: 金额格式化
 * @param {string} money
 * @return {*}
 */
export const formatMoney = (money: string) => {
  return parseFloat(money)
    .toString()
    .split('')
    .reverse()
    .join('')
    .replace(/(\d{3})/g, '$1,')
    .replace(/\\,$/, '')
    .split('')
    .reverse()
    .join('')
}

/**
 * @description: 设置页面传参值
 * @return {*}
 */
export const setSessionStorageKey = (
  value: string,
  key: string = DEFAULT_SESSION_PAGE_KEY
) => {
  window.sessionStorage.setItem(key, value)
}

/**
 * @description: 获取页面传参值
 * @return {*}
 */
export const getSessionStorageValue = (
  key: string = DEFAULT_SESSION_PAGE_KEY
) => {
  return window.sessionStorage.getItem(key)
}

/**
 * @description: 移除页面传参值
 * @return {*}
 */
export const removeSessionStorageKey = (
  key: string = DEFAULT_SESSION_PAGE_KEY
) => {
  window.sessionStorage.removeItem(key)
}

/**
 * @description: 是否是邮箱格式
 * @return {*}
 */
export const isEmail = (email: string) => {
  const reg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/
  return reg.test(email)
}

/**
 * @description: 打开teams或者email
 * @param {string} email
 * @param {*} type teams  email
 * @return {*}
 */
export const openTeamsOrEmail = (
  email: string,
  type = 'teams',
  subject = ''
) => {
  if (email && isEmail(email)) {
    let url = ''
    if (type === 'teams') {
      url = `https://${type}.microsoft.com/l/chat/0/0?users=${email}`
    } else {
      const { user } = store.getState()
      const userInfoMail = user.userInfo.mail
      url = userInfoMail
        ? `mailto:${email}?subject=${subject}&cc=${userInfoMail}&subject=`
        : ''
    }
    if (url) {
      window.open(url)
    } else {
      showMsg({
        type: 'warning',
        content: t('Missing data')
      })
    }
  } else {
    showMsg({
      type: 'warning',
      content: t('The email format is incorrect')
    })
  }
}

/**
 * @description: 格式化时间
 * @param {string} dateStr
 * @return {*}
 */
export const formatDate = (dateStr?: any): string => {
  return dateStr ? moment(dateStr).format('YYYY-MM-DD') : ''
}

/**
 * @description: 转换数字
 * @param {any} str
 * @return {*}
 * @Date Changed:
 */
export const convertNumber = (str: any) => {
  const val = Number(str)
  return isNaN(val) ? 0 : val
}

/**
 * @description: 是否是非空数组
 * @param {any} obj
 * @return {*}
 * @Date Changed:
 */
export const isNotEmptyArr = (obj: any): boolean => {
  return isArray(obj) && !isEmpty(obj)
}

/**
 * @description: 获取星级
 * @param {any} str
 * @return {*}
 */
export const getBudgetLevel = (str: any): number => {
  const num = convertNumber(str)
  if (0 <= num && num <= 5000) {
    return 1
  } else if (5000 <= num && num <= 10000) {
    return 2
  } else if (10000 <= num && num <= 50000) {
    return 3
  } else if (50000 <= num && num <= 100000) {
    return 4
  } else if (100000 <= num) {
    return 5
  } else {
    return 0
  }
}

/**
 * @description: 给指定的字符串插入特定字符串
 * @param {string} str 指定的字符串
 * @param {string} group 插入的
 * @return {*}
 */
export const insertSpecStr = (str: string, group?: string[]): string => {
  try {
    if (str) {
      if (group) {
        const groupArr = isArray(group) ? group : [group]
        const reg = new RegExp(groupArr.join('|'), 'gim')
        // 特殊字符不能识别，所以要在str上加入正则转义 \
        return str.replace(reg, function ($1) {
          return `【&${$1}&】`
        })
      }
      return str
    } else {
      return ''
    }
  } catch {
    return str
  }
}

/**
 * @description: 全角转半角，例如 ： ｈｕａｗｅｉ ==> huawei
 * @param {string} Str
 * @return {*}
 */
export const toDBC = (Str: string) => {
  var DBCStr = ''
  for (var i = 0; i < Str.length; i++) {
    var c = Str.charCodeAt(i)
    if (c === 12288) {
      //如果需要滤空操作，则只需注释下面这行代码即可
      DBCStr += String.fromCharCode(32)
      continue
    }
    if (c > 65280 && c < 65375) {
      DBCStr += String.fromCharCode(c - 65248)
      continue
    }
    DBCStr += String.fromCharCode(c)
  }
  return DBCStr
}

/**
 * @description: 匹配字符串，将全角转半角
 * @param {string} str
 * @return {*}
 */
export const matchToDBC = (str: string) => {
  return str.replace(/[\uff00-\uffff]/g, function ($1) {
    var c = $1.charCodeAt(0)
    return `${String.fromCharCode(c - 65248)}`
  })
}

/**
 * @description: 匹配字符串，将半角转全角 ， huawei ==> ｈｕａｗｅｉ
 * @param {string} str
 * @return {*}
 */
export const matchToBC = (str: string) => {
  return str.replace(/[^\uff00-\uffff]/g, function ($1) {
    var c = $1.charCodeAt(0)
    return `${String.fromCharCode(c + 65248)}`
  })
}

/**
 * @description: 用于前端脏数据过滤用的，用于清除数据中或等值匹配的空数据为 Unknown
 * @param {any} content
 * @return {*}
 */
export const filterDirtyDataContent = (content: any) => {
  if (content) {
    if (typeof content === 'string') {
      // 因为存在对应的英文，这里的 null / NaN  字符串无法转换了, 只对 null / NaN 值做强转换
      // const regStr = /NaN|undefined|Not Applicable|Not Specified/gi
      const regStr = /\b(NaN|undefined|Not Applicable|Not Specified)\b/gi
      return ['null', 'NaN'].includes(content)
        ? t('Unknown')
        : content?.replace(regStr, '') || t('Unknown')
    } else {
      return content
    }
  } else {
    // 兼容数字0的情况
    return typeof content !== 'number' ? t('Unknown') : content
  }
}

type EqualObjType = {
  [key: string]: any
}

/**
 * @description: 判断两个对象属性值是否相等，不一定是对象
 * @param {unknown} obj1
 * @param {unknown} obj2
 * @return {*}
 */
export const isEqual = (obj1: unknown, obj2: unknown) => {
  if (isObject(obj1) && isObject(obj2)) {
    let obj1Keys = Object.keys(obj1)
    let obj2Keys = Object.keys(obj2)
    // 先判断长度就可以过滤一些
    if (obj1Keys.length !== obj2Keys.length) {
      return false
    } else {
      for (let key in obj1) {
        // 递归比较当前每一项
        const res = isEqual(
          (obj1 as EqualObjType)[key],
          (obj2 as EqualObjType)[key]
        ) // 如果碰到一个不一样的就返回 false
        if (!res) {
          // 跳出for循环
          return false
        }
      }
      return true
    }
  } else {
    return obj1 === obj2
  }
}

/**
 * @description: 校验是否是符合格式的json
 * @param {*} jsonStr
 * @return {*}
 */
export function isValidJSON(jsonStr: string): boolean {
  try {
    JSON.parse(jsonStr)
    return true
  } catch (error) {
    console.log('json解析出错')
    return false
  }
}

/**
 * @description: 处理新返回的资源列表
 * @param {Types.AssetItemType[]} assets
 * @return {*}
 */
export function dealAssets(
  assets: Types.AssetItemType[]
): Types.CategoriesItemType[] {
  if (Array.isArray(assets) && assets?.length) {
    const recFun = (
      tData: any[],
      pKey?: string
    ): Types.CategoriesItemType[] => {
      if (Array.isArray(tData) && tData.length) {
        return tData.filter((item: Types.CategoriesItemType) => {
          const { child } = item
          if (child && child?.length) {
            item.child = recFun(child, item.key)
            return true
          }
          if (pKey) {
            const nKey = `${pKey}.${item.key}`
            const filterArr = assets.filter((ele) => ele.category === nKey)
            if (filterArr?.length) {
              item.data = filterArr
              return true
            }
          }
        })
      } else {
        return []
      }
    }

    const nList: Types.CategoriesItemType[] = recFun(
      cloneDeep(OTHER_CATEGORIES_LIST)
    )
    const newCategoriesList: Types.CategoriesItemType[] = nList.filter(
      (ele) => ele.child?.length
    )
    ALL_CATEGORIES_LIST[0].data = assets
    newCategoriesList.unshift(...ALL_CATEGORIES_LIST)

    return newCategoriesList
  } else {
    return []
  }
}

// 下载链接
export function downLoadFile(url: string, title: any = '文件') {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.setAttribute('download', title)
  link.style.display = 'none' // 不可见
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(link.href) // 释放url
  document.body.removeChild(link) // 清除残留的文档片段<a></a>
}

// 校验是否是http/https链接
export function isHttp(str: string): boolean {
  const reg = /(http|https):\/\/([\w.]+\/?)\S*/
  return reg.test(str)
}

// 获取文件后缀名
export function getExtName(url: string): string {
  if (url) {
    const str = url.split('?')[0]
    return str.substring(str.lastIndexOf('.')).toLocaleLowerCase()
  } else {
    return ''
  }
}
