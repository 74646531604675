import { createSlice } from '@reduxjs/toolkit'
import { routerItem } from '@/router'
// 菜单数据
export interface menusState {
  menuInfo: Record<string, Partial<routerItem>>;
  authRouterList: routerItem[];
}

const initialState: menusState = {
  menuInfo: {}, // 菜单对应的信息
  authRouterList: [] // 已经授权的菜单
}

export const menusSlice = createSlice({
  name: 'menus',
  initialState,

  reducers: {
    // 这里保存的菜单是与后台进行匹配后的带权限的菜单信息
    saveMenusInfo: (state, action) => {
      state.menuInfo = JSON.parse(action.payload)
    },
    // 所有已经授权的路由
    saveAuthRouter: (state, action) => {
      state.authRouterList = JSON.parse(action.payload)
    }
  }
})

export const { saveMenusInfo, saveAuthRouter } = menusSlice.actions

export default menusSlice.reducer
