import React from 'react'
import { Outlet } from 'react-router-dom'
import ChildrenPage from '@/component/children-page'
import { PBaseLayout } from '@pico-npm/pico-comm'
import { HeaderRight } from '@/component/header-right'
import { useNavigate } from 'react-router-dom'

export interface baseLayoutProps {
  logo?: string
}
export const PTopLayout = (props: baseLayoutProps) => {
  const navigate = useNavigate()

  const logoToPath = () => {
    navigate('/client/home')
  }

  return (
    <PBaseLayout
      logoImg={props.logo || ''}
      logoToPath={logoToPath}
      menuRender={false}
      rightContentRender={() => <HeaderRight />}
    >
      <ChildrenPage>
        <Outlet></Outlet>
      </ChildrenPage>
    </PBaseLayout>
  )
}
