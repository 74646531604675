// 过滤meta里面有属性是isHiddenMenu的菜单，不显示在左侧
export const filterHiddenMenu = (parentRouterList) => {
  let leftMenuList = []
  if (parentRouterList.children) {
    leftMenuList = parentRouterList.children.filter(
      (i) => !i?.meta?.isHiddenMenu
    )
  }
  return leftMenuList
}

/**
 * 通过当前的pathName找到其对应的菜单列表
 * @params routerList 所有已经授权的路由表
 * @params currentPath 当前页面的路由地址
 * 返回当前路由对应的父级路由表
 */

export const findMenuList = (routerList, currentPath) => {
  // console.log('xx', getCascaderFullValue(routerList, currentPath))
  // const result = getCascaderFullValue(routerList, currentPath)
  // let currentItem = routerList.filter((item) => result.includes(item.key))
  // console.log('currentItem', currentItem)
  // const _pathToRegexp = require('path-to-regexp')
  const currentPathArr = currentPath.split('/') // 通过/ 切割路由地址，有两个 / 的才是真正的路由页面，也就是有子菜单的
  let topParent = []
  let currentMenuList = []

  if (currentPathArr.length < 3) {
    return []
  }

  let currentPathToParent = getCascaderFullValue(routerList, currentPath)
  if (currentPathToParent.length > 1) {
    topParent = currentPathToParent[0]
  }

  // to do 这里只支持到两级的路由，需要优化
  // routerList.forEach((r) => {
  //   if (r?.children && Array.isArray(r.children) && r.children.length) {
  //     r.children.forEach((c) => {
  //       const urlReg = _pathToRegexp.pathToRegexp(c.key)
  //       if (urlReg.test(currentPath)) {
  //         currentPathToParent = r
  //       }
  //     })
  //   }
  // })

  if (topParent) {
    currentMenuList = filterHiddenMenu(topParent)
  }

  return currentMenuList
}
const _pathToRegexp = require('path-to-regexp')
function getCascaderFullValue(cascader, id) {
  let cascaderFullValue = [id]
  _getParentId(cascaderFullValue, cascader, cascader, id)

  return cascaderFullValue

  /**
   *
   * @param {Array} cascaderFullValue value for return
   * @param {Array} cascader value for loop
   * @param {Array} _cascader value of original
   * @param {number} _id  value of bottom item
   */
  function _getParentId(cascaderFullValue, cascader, _cascader, _id) {
    if (!Array.isArray(cascader)) return

    for (let cascaderItem of cascader) {
      if (!Array.isArray(cascaderItem.children)) continue
      let index = cascaderItem.children.findIndex((item) => {
        const urlReg = _pathToRegexp.pathToRegexp(item.key)
        return urlReg.test(_id)
      }) //找到返回该元素的位置 有值为0 没有值返回-1
      if (index >= 0) {
        cascaderFullValue.unshift(cascaderItem) // 在数组开头添加元素
        // console.log(
        //   _cascader,
        //   '_cascader',
        //   cascaderItem,
        //   _cascader.findIndex((item) => item.key === cascaderItem.key)
        // )
        if (_cascader.findIndex((item) => item.key === cascaderItem.key) < 0)
          //is original item
          _getParentId(
            cascaderFullValue,
            _cascader,
            _cascader,
            cascaderItem.key
          )
      } else {
        _getParentId(cascaderFullValue, cascaderItem.children, _cascader, _id)
      }
    }
  }
}
