/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2024-09-12 17:59:34
 * @LastEditTime: 2024-09-23 09:20:25
 * @Description: 资源设置
 */
import { http } from '@/utils/http'
import { gUrl, newSniffUrl } from '@/config'

const resourceSetting = {
    // 资源设置
    setResourceSetting: (data: Types.SettingParams): Promise<Types.ResponseData> =>
        http.put(`${gUrl}/setting/company`, data),

    // 获取资源设置
    getResourceSetting: (key: string): Promise<Types.ResponseData> =>
        http.get(`${gUrl}/setting/company/${key}`),

    // 获取资源设置列表
    getResourceSettingList: (): Promise<Types.ResponseData> =>
        http.get(`${newSniffUrl}/my/company/setting`),
}

export default resourceSetting