/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2024-09-12 17:49:55
 * @LastEditTime: 2024-11-05 00:41:09
 * @Description: 用户权限设置
 */

import { http } from '@/utils/http'
import { newSniffUrl, hhrUrl } from '@/config'

const userPermissions = {
    // 获取用户权限列表
    getUserPermissionsList: <T>(params?: T): Promise<Types.ResponseData> =>
        http.get(`${newSniffUrl}/user/permissions`, params),

    // 获取用户权限信息
    getUserPermissionsDetail: (id: number | string): Promise<Types.ResponseData> =>
        http.get(`${newSniffUrl}/user/permission/${id}`),

    // 设置用户权限
    setUserPermission: <T>(params?: T): Promise<Types.ResponseData> =>
        http.post(`${newSniffUrl}/user/permission`, params),

    // 获取用户权限信息
    deleteUserPermission: (id: number | string): Promise<Types.ResponseData> =>
        http.delete(`${newSniffUrl}/user/permission/${id}`),

    // 获取用户角色
    getUserRoles: <T>(params?: T): Promise<Types.ResponseData> =>
        http.get(`${newSniffUrl}/user/meta-data`, params),

    // O365组件获取token
    getO365Token: <T>(params?: T): Promise<Types.ResponseData> =>
        http.get(`${hhrUrl}/o365/token`, params),
}

export default userPermissions