/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2023-02-14 08:38:47
 * @LastEditTime: 2023-08-14 18:13:54
 * @Description:
 */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en_US from './locale/en_US.json'
import zh_CN from './locale/zh_CN.json'
import zh_TW from './locale/zh_TW.json'
// console.log(Object.keys(cn))
// const l = navigator?.language === 'zh-CN' ? 'zh-CN' : 'en-US'
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      'en-US': {
        translation: en_US
      },
      'zh-CN': {
        translation: zh_CN
      },
      'zh-TW': {
        translation: zh_TW
      }
    },
    lng:
      window.localStorage.getItem('pico-pc-lang') ||
      window.localStorage.getItem('umi_locale') ||
      process.env.REACT_APP_LANGUAGE,
    fallbackLng: 'zh-CN',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
export default i18n
