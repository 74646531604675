/**
 * 打平路由的信息，方便通过路由的path来获取对应的路由配置信息
 *
 * */
import { cloneDeep } from 'lodash'
export const RouterInfoPlan = (routerList, routerInfoList = {}) => {
  routerList.forEach((r) => {
    if (r?.children) {
      RouterInfoPlan(r.children, routerInfoList)
    }
    routerInfoList[`${r.key}`] = r
  })
  return routerInfoList
}

const delRouterElement = (routerItem) => {
  delete routerItem.element
}

// 路由只获取需要的信息，也就是把element这个数据剔除
export const getOnlyNeedInfo = (routerList) => {
  // 先过虑掉404的配置，这个不用保存，json也不能转换，必须去掉才不报错
  let newRouterList = cloneDeep(routerList).filter((n) => n.path !== '*')
  // newRouterList
  newRouterList.forEach((r) => {
    if (r?.children && Array.isArray(r.children)) {
      r.children = getOnlyNeedInfo(r.children)
    }

    delRouterElement(r)
  })

  return newRouterList
}
