/*
 * @Author: junsong Chen
 * @Date: 2024-02-02 19:33:11
 * @Email: 779217162@qq.com
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025-01-07 15:55:48
 * @Description: 
 */
import React, { useEffect, useMemo } from 'react'
import { Menu, Dropdown } from 'antd'
import { GlobalOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { User } from '@/component/user'
import { LOCALE_LIST } from '@/config'
import { PButton } from '@/component/p-button/index'
// import { useDispatch, useSelector } from 'react-redux'
// import { changeLocale } from '../../../../base/src/store/models/comm'
import { useTranslation } from 'react-i18next'
import './index.less'

// const { Header } = Layout

export const HeaderRight = () => {
  const { i18n, t } = useTranslation()
  const navigtor = useNavigate()
  // const dispatch = useDispatch()
  // const lngs = useSelector((state) => state.comm.locale)
  // 语言选项
  const lngsItem = LOCALE_LIST.map((item) => {
    let itemObj = {
      key: item.key,
      label: (
        <a
          onClick={() => {
            const to = item.key
            setLocale(to)
          }}
        >
          {item.label}
        </a>
      )
    }
    return itemObj
  })

  const setLocale = (lngs: string) => {
    window.localStorage.setItem('pico-pc-lang', lngs)
    i18n.changeLanguage(lngs)
  }

  const currentLocale = useMemo(() => {
    return i18n.language
  }, [i18n.language])

  useEffect(() => {
    const defaultLocale: string = currentLocale || 'zh-CN'
    setLocale(defaultLocale)
  }, [currentLocale])

  // 语言选项下拉功能
  const LNGS: JSX.Element = (
    <Menu selectedKeys={[currentLocale]}>
      {lngsItem.map((i) => {
        return <Menu.Item key={i.key}>{i.label}</Menu.Item>
      })}
    </Menu>
  )
  return (
    <div className="layout-header">
      <div className="base-layout-header-right">
        {/* 管理控制台 */}
        <PButton
          type="primary"
          className="console"
          authKey="menu.managementConsole:Preview"
          onClick={() => {
            navigtor('/client/management-console')
          }}
        >
          {t('Management Console')}
        </PButton>
        {/* 国际化 */}
        <Dropdown overlay={LNGS} placement="bottom">
          <GlobalOutlined className="right-lng-icon" />
        </Dropdown>
        {/* 用户信息 */}
        <User />
      </div>
    </div>
  )
}
