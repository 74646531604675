/*
 * @Author: junsong Chen 779217162@qq.com
 * @Date: 2023-02-14 08:38:47
 * @LastEditTime: 2024-09-13 23:54:59
 * @Description: 路由
 */
import React from 'react'
import { lazyLoad } from '@/utils/lazyLoad'
import { lazy } from 'react'
import { PTopLayout } from '@/component/p-top-layout'
import { PMenuLayout } from '@/component/p-base-layout'
import { logo } from '@/config'
import type { routerItem } from '.'
import { Button } from 'antd'
import { useNavigate } from 'react-router'

/* export不是default的时候使用这种方法加载组件 */
// const TestSniffProjectList = lazy(() =>
//   import('../pages/client/test-sniff-projectList').then((module) => ({
//     default: module.TestSniffProjectList
//   }))
// )

// export default xxx 直接导出组件使用这种方式引入组件
// const App = lazy(() => import('../App'))

// 搜索首页
const HomePage = lazy(() => import('@/pages/client/home/index'))
// 个人中心
const PersonalCenterPage = lazy(
  () => import('@/pages/client/personal-center/index')
)
// 项目详情页
const Project = lazy(() => import('@/pages/client/project/index'))
// 客户搜索落地页
const ProjectList = lazy(() => import('@/pages/client/project-list/index'))

// 管理员数据质量看板
const DataDashboard = lazy(
  () => import('@/pages/client/admin/data-dashboard/index')
)

// 管理员数据质量看板-详情页面
const DataDashboardDetail = lazy(
  () => import('@/pages/client/admin/data-dashboard-detail/index')
)

// 管理控制台界面
const ManagementConsole = lazy(
  () => import('@/pages/client/management-console/index')
)

export const Client: routerItem[] = [
  {
    path: '/client',
    name: 'ProjectList',
    element: window.__POWERED_BY_QIANKUN__ ? (
      <PMenuLayout logo={false} />
    ) : (
      <PTopLayout logo={logo} />
    ), // 一级路由不用lazyload,不然会有闪屏的问题
    meta: {
      title: 'Project List',
      autoAuth: true
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        element: lazyLoad(<HomePage />), // 二级以上路由使用lazyload
        meta: {
          title: 'Home',
          autoAuth: true,
          isHiddenMenu: true
          // authKey: 'menu.index',
          // btnAuth: ['index_menu.index_Preview']
        }
      },
      {
        path: 'project-list',
        name: 'ProjectList',
        element: lazyLoad(<ProjectList />), // 二级以上路由使用lazyload
        meta: {
          title: 'Project List', // 标题不要有相同的名称，这里用于多语言的key值
          autoAuth: true,
          isHiddenMenu: true
        }
      },
      {
        path: 'data-dashboard',
        name: 'DataDashboard',
        element: lazyLoad(<DataDashboard />),
        meta: {
          title: 'DataDashboard',
          // autoAuth: true,
          authKey: 'menu.dataDashboard'
        }
      },
      {
        path: 'data-dashboard-detail',
        name: 'DataDashboardDetail',
        element: lazyLoad(<DataDashboardDetail />),
        meta: {
          title: 'DataDashboardDetail',
          autoAuth: true,
          isHiddenMenu: true
          // authKey: 'datadetail',
          // btnAuth: ['datadetail']
        }
      },
      {
        path: 'personal-center',
        name: 'PersonalCenter',
        element: lazyLoad(<PersonalCenterPage />),
        meta: {
          title: 'PersonalCenter',
          autoAuth: true
          // authKey: 'menu.personal',
          // btnAuth: ['personal_personal_Preview']
        }
      },
      {
        path: 'management-console',
        name: 'ManagementConsole',
        element: lazyLoad(<ManagementConsole />),
        meta: {
          title: 'ManagementConsole',
          authKey: 'menu.managementConsole'
        }
      }
    ]
  },
  {
    path: '/',
    name: 'Project',
    element: window.__POWERED_BY_QIANKUN__ ? (
      <PMenuLayout
        logo={() => {
          const navigate = useNavigate()
          return <Button onClick={() => navigate(-1)}>Go Back</Button>
        }}
      />
    ) : (
      <PTopLayout logo={logo} />
    ),
    meta: {
      title: 'Project List', // 标题不要有相同的名称，这里用于多语言的key值
      autoAuth: true
    },
    children: [
      {
        path: 'project/:id',
        name: 'Project',
        element: lazyLoad(<Project />), // 二级以上路由使用lazyload
        meta: {
          title: 'Project List', // 标题不要有相同的名称，这里用于多语言的key值
          autoAuth: true
        }
      }
    ]
  }
]
